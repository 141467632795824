// LoadingScreen.tsx
import React, { useEffect, useState } from 'react';
import logo from '../../Multimedia/Logo/MainLogo.webp';
import '../../Styles/Components/_loadingScreen.scss';


interface LoadingScreenProps {
    isLoading: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ isLoading }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 500); // Adjust delay for smooth transition

            return () => clearTimeout(timer);
        } else {
            setIsVisible(true);
        }
    }, [isLoading]);

    if (!isVisible) return null; // Return null when not visible

    return (
        <div className={`loading-screen ${!isLoading ? 'fade-out' : ''}`}>
            <img src={logo} alt="Logo" className="loading-logo" />
        </div>
    );
};

export default LoadingScreen;





