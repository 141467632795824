import React from 'react';
import PolicySection from './PolicySection';
import GavelIcon from '@mui/icons-material/Gavel';
import { PolicyDataSummary, PolicyDataDetailed } from '../../../Data/OurPolicyPageData/OurPolicyPageData';

const TermsOfServices: React.FC = () => {
    return (
        <PolicySection
            title="Terms of Services"
            summary={PolicyDataSummary.TermsOfServices}
            details={PolicyDataDetailed.TermsOfServices}
            icon={<GavelIcon sx={{ color: '#3f51b5' }} />}
        />
    );
};

export default TermsOfServices;