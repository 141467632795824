// src/hooks/useScrollToElement.ts
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

export const useScrollToElement = () => {
    const navigate = useNavigate();

    const scrollToElement = (
        elementId: string,
        pagePath: string = '',
        offset: number = -100
    ) => {
        const scrollToSection = () => {
            scroller.scrollTo(elementId, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset,
            });
        };

        if (pagePath && window.location.pathname !== pagePath) {
            navigate(pagePath);
            // Wait for page to load before scrolling
            setTimeout(scrollToSection, 100);
        } else {
            scrollToSection();
        }
    };

    return scrollToElement;
};