import React from 'react';

interface ImageContainerProps {
    imageUrl: string;
}

const CourseImage: React.FC<ImageContainerProps> = ({ imageUrl }) => {
    return (

            <div
                style={{
                    position: 'relative',
                    width: '100%', // Set fixed width
                    borderRadius: '2rem',
                    margin: '2rem 0rem 2rem 0rem',
                    overflow: 'hidden', // Hide overflow if image is larger
                }}
            >
                <img
                    src={imageUrl}
                    alt="Course"
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        borderRadius: 'inherit'
                    }}
                />
            </div>

    );
};

export default CourseImage;
