import React from 'react';
import { Box, Typography, Rating, Chip } from '@mui/material';
import { styled } from '@mui/system';
import {CourseHeaderData} from "../../../Data/CoursePageData/CoursePageData"

interface CourseHeaderProps {
    data: CourseHeaderData;
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.breakpoints.down('sm') ? "1rem 1.5rem 1rem 1.5rem" :"1.5rem 0rem 1.5rem 2rem",
    margin: "0rem 0rem 0rem 0rem",
    color: 'white',
    backgroundColor: 'black',

}));

const CourseHeader: React.FC<CourseHeaderProps> = ({ data }) => {
    return (

        <StyledBox>
            <Box display="flex" justifyContent="space-between" flexWrap={"wrap"}>
                <Box flexGrow={1} mr={2} textAlign={"left"}>
                    <Typography  sx={{ fontFamily: 'eMed', color: "darkOrange" }} variant="h4" gutterBottom>
                        {data.title}
                    </Typography>
                    <Typography  sx={{ fontFamily: 'Med', color: "lightOrange" }} gutterBottom>
                        {data.description}
                    </Typography>
                    <Box display="flex" alignItems="center" mb={1}>
                        {data.isBestseller && (
                            <Chip label="Bestseller" color="warning" size="small" sx={{ mr: 1 }} />
                        )}
                        <Rating value={data.rating} readOnly precision={0.1} />
                        <Typography sx={{ fontFamily: 'eReg', fontStyle:'italic', ml:1 }} variant="body2">
                            ({data.numRatings.toLocaleString()} Ratings)
                        </Typography>
                        <Typography sx={{ fontFamily: 'eReg', fontStyle:'italic', ml:1 }} variant="body2" >
                            {data.numStudents.toLocaleString()} Students
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                        <Typography sx={{ fontFamily: 'Med',mr: 2}} variant="body2" >
                            🌐 {data.languages.join(', ')}
                        </Typography>
                        <Typography sx={{ fontFamily: 'Med'}} variant="body2">
                            🗣 {data.subtitles.join(', ')}
                        </Typography>
                    </Box>
                    <Typography sx={{ fontFamily: 'Thin',mt:'1.5rem'}} variant="body2"  >
                        Supervised and Researched by{' '}
                        <a style={{ color: 'inherit' }}>
                            {data.instructor}
                        </a>
                    </Typography>
                </Box>

            </Box>
        </StyledBox>
    );
};

export default CourseHeader;