interface PolicySummary {
    PrivacyPolicy: string;
    Certificates: string;
    QualityAssurance: string;
    TermsOfServices: string;
    ReturnsRefundPolicy: string;
}

interface PolicyDetail {
    Detailed: string[];
    HeadingOfEachParagraph: string[];
}

interface PolicyDataDetailedInterface {
    PrivacyPolicy: PolicyDetail;
    Certificates: PolicyDetail;
    QualityAssurance: PolicyDetail;
    TermsOfServices: PolicyDetail;
    ReturnsRefundPolicy: PolicyDetail;
}

const PolicyDataSummary: PolicySummary = {
    PrivacyPolicy: `Individual information/data of our Learners and clients is defended by state of the art security shows and encryption methodologies at Luminary Quran eLearning Institute. The characterization and security of individual information of our understudies is of quintessential significance to us, and we know we are trusted by our clients to keeping up with latest privacy controls and open practices too. This Insurance System and protection strategy portrays the moves we make to accumulate, use, reveal, and secure your data when you access our e-based Islamic learning courses. Adherence to security standards and consistence with applicable assurance guidelines are totally kept up by our security management personnels. By profiting yourself of our services, you certainly agree to the practices framed in this strategy. We welcome you to examine this report completely to acquire a thorough comprehension of our information dealing with rehearses and your privileges relating to your data.`,

    Certificates: `Luminary Quran is committed to providing certificates Our institute awards a Certificate of completion to the deserving students and learners that are enrolled in their respective courses. Students or learners that demonstrate exceptional learning and result are also offered excellency certificates.`,

    QualityAssurance: `Luminary Quran's quality assurance strategy ensures exceptional teaching and a superior learning experience through expert instructors, a comprehensive curriculum, and regular feedback. The strategy encompasses personalized learning plans, interactive classes, and collaboration with parents. By embracing this approach, Luminary Quran sets the benchmark for Quranic education, empowering students to build a profound connection with the Quran.`,

    TermsOfServices: `Luminary Quran eLearning institude is fully confident and committed for your privacy all around. Your personal data and credentials are Fortified with Care, shielded by cutting-edge database technologies and authentication methodologies. We Vow to Protect your personal information with the utmost confidentiality and security.This Privacy Policy Lays it All Out, detailing how we collect, utilize, disclose, and secure your data when you access our e-based Islamic learning courses. We Comply with Confidence, adhering to stringent data protection standards and applicable privacy laws.Using our services renders your commitment to all the policies listed in our policy section and your Explicit Consent to the practices outlined in this policy. We Encourage You to Read On, to grasp our data handling practices and your rights regarding your information.`,

    ReturnsRefundPolicy: `The Returns and Refund Policy at Luminary Quran ensures that students are well-informed about the procedures surrounding class absences, course cancellations, and refund eligibility. Notified absences allow for make-up classes, while unnotified ones lead to forfeited sessions and potential cancellation of enrollment. Refunds are processed within 14 business days after approval and are only issued to the original payment method. Special considerations are made for holidays, and students can request partial refunds within the first two weeks of the course. Luminary Quran retains the right to modify the policy without prior notice.`
};

const PolicyDataDetailed:PolicyDataDetailedInterface = {
    PrivacyPolicy: {
        Detailed: [
            "This Privacy Policy governs the processing of personal information for all users of (link unavailable) and its associated services, outlining our data handling practices and ensuring transparency in our operations.",
            "We collect and process various types of information to deliver and enhance our e-based Islamic learning courses, including personal information such as names, email addresses, and contact details, as well as non-personal information like browser types and IP addresses.",
            "Personal credentials that are secured using state of the art security methods, are utilized primarily to facilitate the delivery of our e-based Islamic learning courses and improve our services, including course administration, progress tracking, and personalized learning experiences.",
            "Luminary Quran security personnels use robust, uptodate and strong security measures to safeguard your personal information from unauthorized access, alteration, disclosure, or destruction, including encryption, secure socket layer technology, and regular security audits.",
            "Luminary Quran eLearning Institute does not engage in the sale, trade, or rental of personal information to third parties, sharing data only with trusted service providers subject to confidentiality agreements, or as required by law or to protect our rights, property, or safety.",
            "Cookies and similar technologies may be used to improve client side browsing and reduce latency, analyze website traffic, and personalize content. Cookies and Session settings ere easily customizeable within the setting of  your browser.",
            "Only the Personal information is saved that is sent to use by the potential client willingly through the fields of registration form. We dont retain and other information in any way possible unless a longer retention period is required by law, with users having the right to access, correct, or delete their personal information.",
            "LuminaryQuran.com is our trademark and registered domain. Our official website does not contain links to third-party websites or services, for which we disclaim responsibility regarding their privacy practices or content, encouraging users to review the privacy policies of any third-party sites they visit.",
            "Luminary Quran holds the rights and legal laws to update this Privacy Policy from time to time to reflect changes in our practices or for legal, operational, or regulatory reasons, notifying users of any material changes through our website and updating the 'Last Updated' date.",
            "In case of any querries, inquiries, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at tel No : +1 (716) 657-6165 or our email (gmail) luminaryquran@gmail.com, and we will respond to your inquiry as promptly as possible."],
        HeadingOfEachParagraph: [
            "PolicyScope: Data Handling Practices and Transparency",
            "DataCollection: Information Gathering and Processing",
            "DataUsage: Purpose and Application of Personal Information",
            "DataSecurity: Protection Measures and Safeguards",
            "DataSharing: Third-Party Disclosure and Confidentiality",
            "CookiePolicy: Website Analytics and Personalization",
            "DataRetention: Storage Duration and User Rights",
            "ExternalLinks: Third-Party Websites and Services",
            "PolicyUpdates: Modifications and Notifications",
            "ContactInformation: Inquiries and Support"]
    },
    Certificates: {
        Detailed: [
            "Exceptional diligence and mastery in Qur'anic studies are recognized through this esteemed certificate, acknowledging outstanding academic achievement. Academic excellence is verified, and students' commitment to divine wisdom is encouraged.",
            "Academic distinction and achievement are honored with this prestigious certificate, conferred upon students attaining a score of 90% or equivalent grade. Outstanding academic performance is formally recognized, and dedication to studies is verified.",
            "Steady progress and effort throughout studies are acknowledged with this certificate, recognizing students' consistent commitment to learning and growth. Academic consistency is verified, and students' ability to sustain effort is demonstrated.",
            "The highest achievement in respective course programs is verified with this elite certificate, presented to students showcasing exceptional academic excellence. Top performers are formally recognized, and outstanding performance is verified.",
            "Successful completion of courses or programs is confirmed with this certificate, verifying students' fulfillment of academic requirements. Academic success is formally recognized, and commitment to studies is confirmed.",
            "Top performance in respective courses or programs is recognized with this esteemed certificate, awarded to students achieving first position. Exceptional academic achievement is formally recognized, and dedication to studies is verified.",
            "Significant progress in studies is verified with this certificate, recognizing students' substantial growth and development. Academic improvement is formally recognized, and commitment to learning is demonstrated.",
            "Active engagement in online classes is recognized with this prestigious certificate, awarded to students demonstrating outstanding participation. Commitment to learning is formally recognized, and active involvement is verified.",
            "Contributions to community service or volunteer work related to Qur'anic studies are acknowledged with this esteemed certificate, recognizing students' dedication to giving back. Commitment to community service is formally recognized, and contributions are verified."
        ],
        HeadingOfEachParagraph: [
            "Certificate of Appraisal: Exceptional Diligence and Mastery Recognized",
            "Certificate of Excellency: Academic Distinction and Achievement Honored",
            "Certificate of Consistency: Steady Progress and Effort Acknowledged",
            "Certificate of Academic Excellence: Highest Achievement Verified",
            "Certificate of Course Completion: Successful Program Completion Confirmed",
            "Certificate of First Position: Top Performance Recognized",
            "Certificate of Most Improved: Significant Progress Verified",
            "Certificate of Outstanding Participation: Active Engagement Recognized",
            "Certificate of Community Service: Contributions to Qur'anic Studies Acknowledged"
        ]
    },
    QualityAssurance: {
        Detailed: [
            "The core foundation of Luminary Quran is that we have 0 compromise policy on our quality. We're all in all committed to exceptional teaching, ensuring a superior learning experience unlike anything that you have experienced before. Our comprehensive quality assurance strategy encompasses these following points:",
            "Our teachers and instructors are well-versed in the domain of their respective courses. They will provide a unique blend of their personal teaching styles while maintaining the highest standards of quality.",
            "Our carefully selected 10 courses offer a nearly flawless multifaceted exploration of various aspects of Islamic learning, navigating the vast ocean of knowledge within this rich and diverse domain.",
            "Regularly recorded classes refine teaching methods, provide feedback, and maintain high standards, while respecting student privacy.",
            "Our tailored learning plans are designed such that it targets exponential improvement of each student's strengths and weak areas.",
            "Regular reviews and constructive feedback sessions ensure student progress, address challenges, and maintain the highest educational standards.",
            "Utilizing modern technologies and teaching tools to enhance the learning experience, fostering a sense of community and respect.",
            "Regular assessments track student progress, offering additional support to achieve Quranic learning objectives.",
            "Collaboration with parents through regular updates, open communication, and addressing concerns.",
            "Upholding a code of conduct promoting respect, kindness, and a positive learning atmosphere.",
            "By embracing our quality assurance strategy, Luminary Quran sets the benchmark for Quranic education, empowering students to build a profound and lasting connection with the Quran."
        ],
            HeadingOfEachParagraph: [
            "Luminary Quran Quality Assurance: Elevating Quranic Education",
            "Expert Quran Instructors",
            "Comprehensive Islamic Learning Curriculum",
            "Quality Assurance through Class Recordings",
            "Personalized Quran Learning",
            "Cyclic Feedback and Improvement",
            "Engaging and Interactive Learning Environment",
            "Student Success and Progress",
            "Parental Involvement and Communication",
            "Safe and Respectful Learning Environment",
            "Conclusion"
        ]
    },
    TermsOfServices : {

        Detailed: [
            "Our Institute restricts the client/student to abide by the following Terms of Service that govern the use of our online services.",
            "We offer our services on an 'as-is' basis without any long-term guarantees. While we strive for accuracy, users should acknowledge the potential for errors in the content.",
            "Clients are responsible for safeguarding their account information from their end. Luminary Quran Institute is not liable for unauthorized access to any individual's account information.",
            "All course materials are the exclusive property of Luminary Quran Institute and cannot be reproduced without explicit permission.",
            "Payment terms are detailed on our website. Refunds are only allowed in failure scenarios on our end or if the services are not provided as agreed.",
            "We reserve the right to create, update, change, or discontinue services without prior notice. Any claimed resultant damages to clients and learners are not our responsibility.",
            "Users agree to protect Luminary Quran Institute from any claims or liabilities arising from their use of our services.",
            "Our Privacy Policy outlines how we handle user data. By using our services, users consent to data collection and usage practices. There is a dedicated section of the Privacy Policy on our website for an in-depth view.",
            "External links provided do not imply endorsement. Luminary Quran Institute is not responsible for third-party content.",
            "Disputes will be resolved through binding arbitration as outlined in our dispute resolution policy.",
            "These Terms constitute the entire agreement, superseding any prior understandings.",
            "Students must adhere to their confirmed class schedule consistently. Schedule changes are permitted only in emergencies or significant time-related issues. Frequent changes may disrupt the learning process.",
            "Do not finalize class schedules directly with teachers without informing the Admin Team, as it may affect your learning experience.",
            "Teachers are instructed to start classes on time. If your teacher is more than 5 minutes late, inform us via WhatsApp promptly. The Institute is responsible for missed classes only if informed in a timely manner.",
            "Notify the Admin Team or teacher at least 2 hours before your class if you need to take leave. Unnotified absences will be marked as absent, and there will be no make-up for these classes.",
            "Consecutive unnotified Absentees portrays non-seriousness and these students are at extreme risk of cancellation of their course altogether without any refund in any way possible",
            "Missed classes are non-refundable. However, make-up classes can be scheduled if you inform the admin in advance. These make-up classes must be taken within 30 days.",
            "Monthly fees are payable in advance from the date of registration. After trial classes, you may discuss fee plans for your desired course with the Admin Team. Fees must be paid on the due date.",
            "The Institute reserves the right to stop classes if payment is overdue by more than 7 days without notice or valid reason.",
            "Once classes begin in a month, the full month's fees are required.",
            "We prioritize your security. Do not share your contact information (phone number, email address, social media accounts, credit card information, etc.) with any teacher. The Institute is not responsible for any misuse of shared information.",
            "Teachers are prohibited from requesting or sharing personal contact information with students or parents. Report any such incidents to us immediately.",
            "Do not discuss payments or other financial matters with teachers. Contact the Admin Team for any such concerns."
        ],

        HeadingOfEachParagraph: [
            "1. Agreement to Terms",
            "2. Disclaimer of Warranty",
            "3. User Responsibilities",
            "4. Intellectual Property Rights",
            "5. Payment and Refunds",
            "6. Service Modifications",
            "7. Indemnification",
            "8. Privacy",
            "9. Third-Party Links",
            "10. Dispute Resolution",
            "11. Entire Agreement",
            "12. Daily Class Schedule and Attendance",
            "13. Coordination with Admin",
            "14. Punctuality",
            "15. Leave Notification",
            "16. Absence Policy",
            "17. Missed Classes",
            "18. Payment Policies",
            "19. Overdue Payments",
            "20. Monthly Commitment",
            "21. Customer Security",
            "22. Teacher Contact Policy",
            "23. Payment Discussions"
        ]

    },

    ReturnsRefundPolicy: {
        Detailed: [
            "Should you inform us prior to your scheduled class, arrangements for a make-up session will be undertaken. This courtesy is extended to ensure that your learning trajectory remains uninterrupted. Notification is required at least one hour before the scheduled time to facilitate this process.",
            "Absences that go uncommunicated will regrettably result in forfeited lessons, with no option for refunds or rescheduled classes. While unforeseen events may occur, unnotified absences cannot be accommodated within our policy framework.",
            "Accumulation of more than three unnotified absences will lead to a void in any refund requests. Additionally, continued participation may be discontinued, necessitating re-enrollment. Proactive communication is encouraged to avoid such outcomes.",
            "Eid, government, and internationally recognized holidays are observed as paid leave within our institution. Classes missed during these periods are non-refundable. We advise planning around these dates to ensure uninterrupted learning.",
            "Upon the administrative approval of your refund request, the process shall commence and be completed within 14 business days, exclusive of public holidays. Delays in reflection within your account may arise due to procedural timelines of financial institutions.",
            "In instances where Luminary Quran must cancel a course, a full refund will be issued. Conversely, should the cancellation be initiated by the student, refunds will not be provided.",
            "Dissatisfaction within the initial fortnight of the course permits a refund request, wherein 50% of the fee will be returned, and the remaining balance forfeited. This provision is designed to balance fairness and commitment.",
            "In case of any course that is subjected to Completion percentile of more than half,renders the student ineligible for any refund for that respective course.",
            "Refunds shall be remitted exclusively to the original payment method, preserving the integrity of the transaction.",
            "Luminary Quran reserves the autonomous right to amend this refund policy at any given time, without prior notice to participants."
        ],
        HeadingOfEachParagraph: [
            "Provision for Notified Absences",
            "Policy on Unnotified Absences",
            "Consequences of Multiple Unnotified Absences",
            "Holiday Observance and Paid Leave Policy",
            "Timeline for Refund Processing",
            "Cancellation by Luminary Quran",
            "Policy on Student-Initiated Cancellations",
            "Conditions for Refund Eligibility",
            "Refund Issuance Method",
            "Policy Amendments"
        ]
    }

};


export {PolicyDataSummary,PolicyDataDetailed}
