// AboutUs.js
import React,{ lazy, Suspense }  from 'react';
import {faqHomeData} from "../Data/HomePageData/FAQ_homeData"

import { Container, Typography, Box, List, ListItem, Skeleton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import {Element} from "./ComponentsExports"



const CheckCircleIcon = lazy(() => import('@mui/icons-material/CheckCircle'));
const FlagIcon = lazy(() => import('@mui/icons-material/Flag')); // For mission
const VisibilityIcon = lazy(() => import('@mui/icons-material/Visibility'));
const BalanceIcon = lazy(() => import('@mui/icons-material/Balance'));


const ServicesSection = lazy(() => import("./Components/ServicesandCourses"));
const CustomizedAccordions = lazy(() => import('./Components/FaqAccordian'));




const values = [
    "Authenticity & Leadership: Authenticity and moral leadership are prioritized by Luminary Quran, fostering an environment of trust and transparency in every collaboration.",
    "Excellency: Exceptional educational experiences are delivered by Luminary Quran, providing outstanding resources that empower students to reach their full potential.",
    "Cherishing Diversity: Students joining us from different and diverse backgrounds are welcomed by Luminary Quran, celebrating inclusivity as a source of strength and resilience.",
    "Empathy and courtesy: A supportive and compassionate environment is cultivated by Luminary Quran, ensuring that each student feels valued and genuinely supported.",
    "Innovative and uptodate: Opportunities are enhanced by Luminary Quran through the utilization of cutting-edge technology, making them more impactful and accessible for all."
];





const AboutUs = () => {




    return (
        <div>

            {/*BACKGROUND Section*/}
            <Container>
                <Box my={4}>
                    <Typography sx={{ fontFamily: 'eBold', marginTop:'2rem', marginBottom:'2rem' }} variant="h3" component="h1" gutterBottom>
                        ABOUT US
                        <Divider textAlign={"left"}  sx={{fontFamily:"Thin",fontSize:"0.8rem"}} >ABOUT US   SECTION</Divider>
                    </Typography>
                    <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                        Luminary Quran, an online based Islamic Academy , where divine Islamic knowledge is shared through a variety of enriching courses, delivered by a dynamic virtual academy. Learning is ensured to be not only effective but also liberating and accessible for all by passionate educators. Carefully and detailed structured and personalized programs are offered, illuminating the profound wisdom of the Quran and the elegance of the Sunnah in a refreshing way. Whether a beginner or an advanced learner, courses are designed to meet diverse needs. A journey of enlightenment awaits by joining the global community today.
                    </Typography>
                </Box>
            </Container>

            {/*Our mission*/}
            <Container>
                <Box my={4} textAlign="center">

                    <Typography sx={{ fontFamily:"eMed",marginTop:'4rem',}}  variant="h4" gutterBottom>
                       OUR MISSION  <FlagIcon style={{ fontSize: 50, color: 'orange' }} />
                    </Typography>
                    <Divider />
                    <Box my={2}>
                        <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                            The mission of Luminary Quran is to uphold the divine message and Quranic wisdom by offering inclusive and affordable programs tailored to meet the unique needs of every learner. Their commitment extends to providing personalized plans and ensuring that financial assistance, including impressive discounts, is available to those in need, making Quranic education accessible to all.       </Typography>
                    </Box>
                </Box>
            </Container>


            {/*Our vision*/}
            <Container>
                <Box my={4} textAlign="center">

                    <Typography  sx={{ fontFamily: 'eMed',marginTop:'4rem' }} variant="h4" gutterBottom>
                        OUR VISION <VisibilityIcon style={{ fontSize: 50, color: 'orange' }} />
                    </Typography>
                    <Divider />
                    <Box my={2}>
                        <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                            The Grand plan and foreseeable vision of Luminary Quran is that the academy envisions a future where the light of Quranic knowledge reaches every home, touching hearts and transforming lives. With unwavering dedication, We strive to create a global community of enlightened individuals who embrace Islam's teachings. Our grand ambition is to inspire generations, fostering a deep, unbreakable connection with the divine message for a brighter, more spiritually enriched world all while staying convenient at the palm of your hand. ( using technology)
                        </Typography>
                    </Box>
                </Box>
            </Container>

            {/*Our Values Section*/}
            <Container>
                    <Suspense fallback={
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="200px"
                            animation="wave"

                        />   } >
                <Box my={4}>

                    <Typography  sx={{ fontFamily: 'eMed',marginTop:'4rem' }} variant="h4" gutterBottom>
                        OUR VALUES <BalanceIcon style={{ fontSize: 50, color: 'orange' }} />
                    </Typography>
                    <Divider />
                    <List >
                        {values.map((value, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <Suspense fallback={<Skeleton width={50} height={50} />}>
                                    <CheckCircleIcon />
                                    </Suspense>
                                </ListItemIcon>
                                <ListItemText primary={value}
                                              primaryTypographyProps={{
                                                  fontFamily: 'Reg',
                                                  fontSize:"1.25rem"

                                              }}
                                        />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                </Suspense>
            </Container>
                <Suspense fallback={
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="200px"
                        animation="wave"
                        sx={{
                            backgroundColor: 'red',
                            opacity: 0.5,
                        }}
                    />
                    }
                    >
                    <Element name="FAQsFooter">
                        <CustomizedAccordions FaqData={faqHomeData} />
                    </Element>
                </Suspense>

            {/*courses and services*/}
            <Suspense
                fallback={
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="200px"
                        animation="wave"
                        sx={{
                            backgroundColor: 'red',
                            opacity: 0.5,
                        }}
                    />
                }
            >
                <ServicesSection/>
            </Suspense>

            <Divider textAlign={"right"}  sx={{fontFamily:"Thin",fontSize:"0.8rem", mt:"4rem"}} > ABOUT US</Divider>
        </div>


    );

};

export default AboutUs;
