import React, { lazy, Suspense,useLayoutEffect  } from "react";
import { Skeleton,Grid } from "@mui/material";
import "../Styles/Pages/_home.scss";
import { GradientButton, Element } from '../Pages/ComponentsExports';
import LoadingScreen from './Components/LoadingScreen';  // Import the Loading Screen

//Custom hooks
import { useBackgroundDataFetch } from '../Hooks/DataFetch';


// Lazy load components
const ImportanceCarousel = lazy(() => import('./Components/HomePage/ImportanceCarousal'));
const WhyUsCard = lazy(() => import('./Components/HomePage/WhyUsCard'));
const CurrencyCard = lazy(() => import('./Components/HomePage/CurrencyCard'));
const MovingComponent = lazy(() => import('react-moving-text'));
const TestimonialCarousel = lazy(() => import('./Components/HomePage/testimonialsHomepageCarousal'));
const FAQAccordion = lazy(()=> import("./Components/FaqAccordian"))

interface HomeProps {
    onHomeLoaded: () => void;
}

const Home: React.FC<HomeProps> = ({ onHomeLoaded }) => {

    const { DATA, isLoading, error } = useBackgroundDataFetch();

    // Use useLayoutEffect to ensure the effect runs before the browser repaints
    useLayoutEffect(() => {
        const handleLoad = () => {
            onHomeLoaded();  // Notify parent component
        };

        // Check if the content has already loaded, if not wait for it to load
        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, [onHomeLoaded]);



    const MovingText: React.FC<{ dataText: string[] }> = ({ dataText }) => (
        <div className="moving-text">
            <Suspense fallback={<Skeleton variant="text" width="100%" height={60} />}>
                <MovingComponent
                    type="typewriter"
                    dataText={dataText}
                    delay={0.005}
                    speed={2000}
                />
            </Suspense>
        </div>
    );



    return (
        <>
            <LoadingScreen isLoading={isLoading} />
        {!isLoading && !error && DATA && (
        <div className="main-container">
            <div className="children-1">
                <div className="CoverContainer">

                    <div className="left-div">
                        <span className="FantasticHeading">Illuminate the heart with QURAN</span>
                        <span className="NormalHeading" style={{fontStyle:"italic"}}>Learn QURAN Online with Experts</span>
                        <MovingText dataText={[
                            "Noorani Qaida(KIDS)",
                            "Reading Quran",
                            "Quran Memorization",
                            "Tafsir Course",
                            "Ten Qirat Courses",
                            "Basic/Advanced Tajweed Courses",
                            "Online Ijazah Program",
                            "Arabic Language Courses",
                            "Islamic Studies",
                            "Islamic Supplications",
                            "Prayer/Hadith lessons"
                        ]} />
                        <div className="MainPageButtons">
                            <GradientButton text="Register" />
                            <GradientButton text="Free Trial" />
                        </div>
                    </div>

                </div>
            </div>

            <Suspense fallback={<Skeleton variant="rectangular" width="100%" height={400} />}>
                <div className="children-2">
                    <div className="SectionHeadingh2">
                        <MovingText dataText={["STARTING IN THE NAME OF ALLAH", "THE MOST MERCIFUL", "THE MOST BENEVOLENT", "THE MOST BENEFICIAL"]} />
                    </div>
                    <div style={{padding:"1rem 1rem 1rem 1rem"}}>

                        <span className="GlobalMainHeading" >LUMINARY QURAN <br/> <span className={"GlobalMainHeadingSub"}>Our Passion</span> </span>
                        {DATA.introduction}
                    </div>
                    {/*from here */}
                    <Grid container spacing={2} sx={{padding:"0rem 1.5rem 0rem 1.5rem"}}>
                        {/* Carousel Section */}
                        <Grid item xs={12} md={7}>
                            <Suspense fallback={<Skeleton variant="rectangular" width="100%" height={400} />}>
                                <span className="GlobalMainHeading">OUR COURSES <br/>  <span className={"GlobalMainHeadingSub"}>At a Glance</span></span>
                                <div className="carousal-container">
                                    <ImportanceCarousel />
                                </div>
                            </Suspense>
                        </Grid>

                        {/* Quran Section */}
                        <Grid item xs={12} md={5} >
                            <span className="GlobalMainHeading">THE QURAN's <br /> <span className={"GlobalMainHeadingSub"}>Significance</span></span>
                            <div className="importance-container">
                                <div className="importance-points">
                                    {DATA.importancePoints.map((point, index) => (
                                        <div key={index} className="importance-point">
                                            <Suspense fallback={<Skeleton variant="rectangular" width="40px" height="40px" />}>
                                                <point.icon className="importance-icon" />
                                            </Suspense>
                                            <span className={"normal-text"} style={{textAlign:"left"}} >{point.text}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {/*till here*/}

                    <h1 className="GlobalMainHeading" style={{padding:"0",marginBottom:"0rem"}}>The Luminary Difference</h1>
                    <p className={"normal-text"} style={{margin:"0rem 1rem 0rem 1rem",fontStyle:"italic"}}>
                        Luminary Quran is the best choice to partner with for an Illuminated Quranic experience and spritual growth.

                    </p>
                    <Element name="WhyUsFooter">
                        <div className="WhyUsCard-container">
                            {DATA.whyUsCards.map((card, index) => (
                                <WhyUsCard key={index} {...card} />
                            ))}
                        </div>
                    </Element>
                    <div className="GlobalMainHeading" style={{marginBottom:"2rem"}}>Luminary's Plans <span className={"GlobalMainHeadingSub"}>Monthly & Annual</span></div>
                    <div className="currency-cards-container">
                        {DATA.PricesMonthlyData.map((price, index) => (
                            <CurrencyCard key={index} {...price} />
                        ))}
                    </div>

                    <Suspense fallback={<Skeleton variant="rectangular" width="100%" height={400} />}>
                        <div style={{margin:"3.5rem 0rem 2rem 0rem"}}>
                            <span className="GlobalMainHeading" >What Our Luminary Learners say </span>
                            <TestimonialCarousel testimonials={DATA.testimonialsData} />
                        </div>
                    </Suspense>
                </div>
                <FAQAccordion FaqData={DATA.faqHomeData}/>
            </Suspense>

        </div>
    )}
    </>
    );
};

export default Home;