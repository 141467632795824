// TestimonialCard.tsx
import React from 'react';
import { Card, CardContent, Typography, Avatar, Box } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Rating from '@mui/material/Rating';
import {keyframes} from "@mui/system";

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

interface Testimonial {
   userName: string;
    userImage: string;
    userTitle: string;
    userFeedback: string;
    userRating:number
}

interface TestimonialProps {
    testimonial: Testimonial;
}

const TestimonialCard: React.FC<TestimonialProps> = ({testimonial}) => {
        return (
            <Card
                sx={{
                    maxWidth: 345,
                    height: '450px',
                    backgroundColor: '#FFFFFF',
                    color: '#8B4513',
                    position: 'relative',
                    overflow: 'visible',
                    borderRadius:"2rem",
                    border:"2px solid white",
                    boxShadow: '0 0 0 2px white',

                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-10px)',
                        height: '500px',
                        transition: 'all 0.3s ease-in-out'
                    },
                }}
            >
                <CardContent sx={{ pb: 10 }}>
                    <FormatQuoteIcon
                        sx={{
                            fontSize: 120,
                            position: 'relative',
                            transform: "rotate(180deg)",

                            color: 'rgba(255,144,63,0.5)',


                        }}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            mb: 2,
                            fontFamily: 'Thin',
                            fontSize: '0.9rem',
                            lineHeight: 1.6,
                            position: 'relative',

                            zIndex: 0,
                        }}
                    >
                        {testimonial.userFeedback}
                    </Typography>
                    <Rating name="read-only" value={testimonial.userRating} precision={0.1} size={"large"} readOnly />
                </CardContent>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '170px',
                        background: 'linear-gradient(90deg, #FF4500 0%, #FF5700 15%, #FF8C00 30%, #FFA500 45%,  #FFA500 55%, #FF8C00 70%, #FF5700 85%, #FF4500 100%);',
                        backgroundSize: '500% 500%',
                        animation: `${gradientAnimation} 10s ease infinite`,
                        clipPath: 'polygon(0 50%, 50% 0, 100% 50%, 100% 100%, 0 100%)',
                        display: 'flex',
                        flexDirection:'column',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        borderRadius:"2rem",
                        pb: 1,

                    }}
                >
                    <Avatar
                        src={testimonial.userImage}
                        sx={{
                            width: 60,
                            height: 60,
                            border: '3px solid #FFFFFF',
                            position: 'relative',
                            m: "1rem",
                            // zIndex:100


                        }}
                    />
                    <Box sx={{ textAlign: 'center', mb: 1 }}>
                        <Typography
                            variant="h6"
                            sx={{ fontFamily: 'eBold', fontSize: '1rem', color: '#FFFFFF' }}
                        >
                            {testimonial.userName}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontFamily: 'eMed', fontSize: '0.8rem', color: '#FFFFFF' }}
                        >
                            {testimonial.userTitle}
                        </Typography>
                    </Box>
                </Box>
            </Card>
        );
    };

    export default TestimonialCard;