import React from 'react';
import PolicySection from './PolicySection';
import StarIcon from '@mui/icons-material/Star';
import { PolicyDataSummary, PolicyDataDetailed } from '../../../Data/OurPolicyPageData/OurPolicyPageData';

const QualityAssurance: React.FC = () => {
    return (
        <PolicySection
            title="Quality Assurance"
            summary={PolicyDataSummary.QualityAssurance}
            details={PolicyDataDetailed.QualityAssurance}
            icon={<StarIcon sx={{ color: '#4caf50' }} />}
        />
    );
};

export default QualityAssurance;