// OurPolicy.js
import React,{ lazy, Suspense } from 'react';
import { Grid, Typography, Paper, Box, Divider} from '@mui/material';
import { styled  } from '@mui/system';
import {Element} from "./ComponentsExports";

const Certificates = lazy(() => import('./ComponentsExports').then(module => ({ default: module.Certificates })));
const PrivacyPolicy = lazy(() => import('./ComponentsExports').then(module => ({ default: module.PrivacyPolicy })));
const QualityAssurance = lazy(() => import('./ComponentsExports').then(module => ({ default: module.QualityAssurance })));
const ReturnsRefundPolicy = lazy(() => import('./ComponentsExports').then(module => ({ default: module.ReturnsRefundPolicy })));
const TermsOfServices = lazy(() => import('./ComponentsExports').then(module => ({ default: module.TermsOfServices })));


const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    background: 'linear-gradient(90deg, #FF4500 0%, #FF5700 15%, #FF8C00 30%, #FFA500 45%, #FFA900 50%, #FFA500 55%, #FF8C00 70%, #FF5700 85%, #FF4500 100%);',
    borderRadius: "1rem",
}));

const OurPolicy: React.FC = () => {
    return (
        <div>
            <Box my={4}>
                <Typography sx={{ fontFamily: 'eBold', marginTop:'2rem', marginBottom:'2rem' }} variant="h3" component="h1" gutterBottom>
                    OUR POLICIES
                    <Divider textAlign={"left"}  sx={{fontFamily:"Thin",fontSize:"0.8rem"}} >POLICIES SECTION</Divider>
                </Typography>
            </Box>


            <Box className={"FormHeadingMain"}>
                    Quality & Compliance
                <Typography
                    variant="body1"
                    sx={{ fontFamily: 'Thin', color: 'white', mt: 2,  }}
                >
                    Our policies are crystal clear and we aim to assist you by providing Quality of our work, our ceritficates and services
                </Typography>
            </Box>



            <Element name="LegalSection">
                <Grid container spacing={"1rem"}>
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Suspense fallback={<div>Loading...</div>}>
                            <PrivacyPolicy />
                            </Suspense>
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Suspense fallback={<div>Loading Certificates...</div>}>
                                <Certificates />
                            </Suspense>
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Suspense fallback={<div>Loading Quality Assurance...</div>}>
                                <QualityAssurance />
                            </Suspense>
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Suspense fallback={<div>Loading Terms Of Services...</div>}>
                                <TermsOfServices />
                            </Suspense>
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Suspense fallback={<div>Loading Returns Refund Policy...</div>}>
                                <ReturnsRefundPolicy />
                            </Suspense>
                        </StyledPaper>
                    </Grid>
                </Grid>
            </Element>
            <Divider textAlign={"right"}  sx={{fontFamily:"Thin",fontSize:"0.8rem"}} >POLICIES </Divider>
        </div>
    );
};


export default OurPolicy;
