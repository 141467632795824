import React from 'react';
import { Typography, Box, Grid, Card, CardContent, Divider } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(4),
    boxShadow: theme.shadows[3],
    borderRadius: "2rem",
    '&:hover': {
        boxShadow: theme.shadows[6],
    },
    position: 'relative',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    display: 'flex',
    justifyContent: "flex-start",
    flexWrap: 'wrap',
    textAlign: 'left',
    '& > div': {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(3),
        marginLeft: theme.spacing(2)
    },
    margin: theme.spacing(1, 2, 1, 1),
}));

interface CourseDetails2 {
    personalGuidance: string;
    progressMonitoring: string;
    homeworkAndAssignments: string;
    examsAndReviewTests: string;
    registrationProcess: string;
    feesAndPayment: string;
    meetOurInstructors: string;
    learningOutcomes: string[];
}

interface LearningCardProps {
    courseDetails2: CourseDetails2;
}

const LearningCard: React.FC<LearningCardProps> = ({ courseDetails2 }) => (
    <div>
        <Box my={4}>
            <StyledCard>
                <Typography  sx={{ fontFamily: 'eMed' ,marginTop:'1.5rem',marginBottom:'0rem'}} variant="h4" gutterBottom>
                    COURSE ADDITIONAL DETAILS
                    <Divider sx={{marginTop:1.5}} />
                </Typography>
                <StyledCardContent>
                    {Object.entries(courseDetails2).map(([key, value]) => (
                        <Box key={key} mb={3} width="100%">
                            <Typography sx={{ fontFamily: 'eBold', marginTop: '1rem', marginBottom: '1rem' }} variant="h5" gutterBottom>
                                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                            </Typography>
                            {key === 'learningOutcomes' ? (
                                <Grid container spacing={2} justifyContent="center" mt={2}>
                                    {(value as string[]).map((outcome, index) => (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <StyledTypography sx={{ fontFamily: 'Thin' }}>
                                                <div>
                                                    <CheckCircleOutline style={{ color: "darkorange" }} />
                                                    {outcome}
                                                </div>
                                            </StyledTypography>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Typography sx={{ fontFamily: 'Thin' }} variant="body1" paragraph>
                                    {value as string}
                                </Typography>
                            )}
                            <Divider />
                        </Box>
                    ))}
                </StyledCardContent>
            </StyledCard>
        </Box>
    </div>
);

export { LearningCard };