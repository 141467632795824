import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import "../../../Styles/Components/FormStyle/CountryCodePhoneNumber.scss"
import { ErrorMessage } from 'formik';
import {useState} from "react";

interface PhoneNumberInputProps {
    value: string;
    onChange: (value: string | undefined) => void;
    error?: boolean;
    touched?: boolean;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value, onChange, error, touched }) => {
    return (
        <div className="phone-input-container">
            <PhoneInput
                international
                defaultCountry="US"
                value={value}
                onChange={onChange}
                className={`phone-input ${touched && error ? 'error' : ''}`}
            />
            <ErrorMessage name="phoneNumber">
                {(msg) => <div className="error-message">{msg}</div>}
            </ErrorMessage>
        </div>
    );
};

export {PhoneNumberInput};