import React, {useEffect, useState} from 'react';
import {
    Box,
    Container,
    Typography,
    Grid,
    Paper,
    Avatar,
    useTheme,
    useMediaQuery,
    Zoom, Divider, Card, CardMedia, CardContent,
} from '@mui/material';
import {WhyUsCards} from "../../Data/WhyUsCards";

const CAROUSEL_ITEMS = () => import("../../Data/HomePageData/course_carousalData");

interface CarouselItem {
    description:string;
    image: string;
}

const ServicesSection: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [items, setItems] = useState<CarouselItem[]>([]);

    useEffect(() => {
        CAROUSEL_ITEMS().then((module) => {
            setItems(module.default);
        });
    }, []);

    return (
        <Container>
            <Box my={4}>
                <Typography  sx={{ fontFamily: 'eMed',marginTop:'4rem' }} variant="h3" gutterBottom>
                    OUR COURSES & SERVICES
                </Typography>
                <Divider />
            </Box>
            <Box my={6}>
                <Typography sx={{ fontFamily: 'eMed', mb: 4, mt: 4 }} variant="h4" align="center">
                    OUR SERVICES
                </Typography>
                <Grid container spacing={4}>
                    {WhyUsCards.map((service, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Zoom in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        p: "1rem 2rem 0rem 2rem",
                                        m: "1rem 0.5rem 1rem 0.5rem",
                                        borderRadius: "2rem",
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-5px)',
                                            boxShadow: theme.shadows[10],
                                        },
                                    }}
                                >
                                    <Avatar
                                        src={service.imageSrc}
                                        alt={service.title}
                                        sx={{
                                            width: isMobile ? 80 : 120,
                                            height: isMobile ? 80 : 120,
                                            mb: 2,
                                            boxShadow: theme.shadows[3],
                                        }}
                                    />
                                    <Typography sx={{fontFamily:"eBold",textTransform:"uppercase"}} variant="h5" component="h3" gutterBottom>
                                        {service.title}
                                    </Typography>
                                    <Typography sx={{fontFamily:"eReg",color:"#7e7e7e"}} variant="body1" >
                                        {service.description}
                                    </Typography>
                                </Paper>
                            </Zoom>
                        </Grid>
                    ))}
                </Grid>
            </Box>

                <Box my={4}>
                    <Typography  sx={{ fontFamily: 'eMed', marginTop:'4rem' }} variant="h4" gutterBottom>
                        OUR COURSES ( major )
                    </Typography>
                    <Divider />


                    {/* Courses Section */}
                    <Box my={4}>
                        <Grid container spacing={4}>
                            {items.map(({  description, image }, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={image}
                                        />
                                        <CardContent>
                                            <div style={{ fontSize: "1rem ",fontFamily:"eReg",color:"#7e7e7e" }}>
                                                {description}
                                            </div>
                                            </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>

        </Container>
    );
};

export default ServicesSection;