// src/components/ScrollLink.tsx
import React from 'react';
import { useScrollToElement } from './navigationHookCustom';

interface ScrollLinkProps {
    to: string;
    pagePath?: string;
    offset?: number;
    className?: string;
    children: React.ReactNode;
}

export const ScrollLink: React.FC<ScrollLinkProps> = ({
                                                          to,
                                                          pagePath,
                                                          offset,
                                                          className,
                                                          children,
                                                      }) => {
    const scrollToElement = useScrollToElement();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        scrollToElement(to, pagePath, offset);
    };

    return (
        <div onClick={handleClick} className={className} role="button" style={{ cursor: 'pointer' }}>
            {children}
        </div>
    );
};
