// types.ts
export interface TeamMember {
    image: string;
    name: string;
    title: string;
    shortDescription: string;
    longDescription: string[];
    age?: number;
    status?: string;
    location?: string;
    archetype?: string;
    traits?: string[];
    personality?: {
        [key: string]: number;
    };
    motivations?: {
        [key: string]: number;
    };
    frustrations?: string[];
    goals?: string[];
    favoriteBrands?: string[];
}

export const teamData: TeamMember[] = [
    { // teacher 1
        image: require("../../Multimedia/Images/ourTeamsPage/islamicMaleTeacher.webp"),
        name: 'Ahmed SULTAN',
        title: 'Qari and Hafiz e Quran',
        shortDescription: 'Expert in Quran Recitation, Tajweed, and Hifz',
        longDescription: [
            'Ahmed Saeed is a highly respected teacher at Luminary Quran Institute with a deep passion for the Quran.',
            'He brings a wealth of knowledge and spiritual insight to his teaching, helping students perfect their recitation and memorize the Quran with precision.'
        ],
        age: 45,
        status: 'Married',
        location: 'Karachi, Pakistan',
        archetype: 'Qari',
        traits: ['Dedicated', 'Knowledgeable', 'Patient'],
        personality: {
            Introvert: 4,
            Extrovert: 6,
            Analytical: 7,
            Creative: 6,
        },
        motivations: {
            Challenge: 7   ,
            Growth: 9,
            Teamwork: 8,
        },
        frustrations: ['Inconsistent practice by students', 'Lack of Tajweed awareness'],
        goals: ['Help students achieve perfect Quranic recitation', 'Memorize the entire Quran with correct Tajweed'],
        favoriteBrands: ['Darul Uloom Karachi', 'Islamic Research Academy', 'Al-Huda International'],
    }, // teacher 1
    { // teacher 2
        image: require("../../Multimedia/Images/ourTeamsPage/islamicFemaleTeacher.webp"),
        name: 'Fatima Zahra',
        title: 'Arabic Language and Tafsir Specialist',
        shortDescription: 'Expert in Tafsir and Quranic Arabic',
        longDescription: [
            'Fatima Zahra is known for her deep understanding of Tafsir and Quranic Arabic, bringing clarity and depth to her students\' learning.',
            'Her engaging teaching style makes her an excellent guide for those wishing to deepen their Quranic understanding.'
        ],
        age: 34,
        status: 'Married',
        location: 'Cairo, Egypt',
        archetype: 'Scholar',
        traits: ['Insightful', 'Patient', 'Engaging'],
        personality: {
            Introvert: 5,
            Extrovert: 5,
            Analytical: 8,
            Creative: 7,
        },
        motivations: {
            Challenge: 8,
            Growth: 9,
            Teamwork: 7,
        },
        frustrations: ['Misinterpretation of Quranic verses', 'Lack of student engagement in Tafsir studies'],
        goals: ['Publish a comprehensive Tafsir guide', 'Enhance students\' understanding of Quranic Arabic'],
        favoriteBrands: ['Al-Azhar University', 'Dar al-Ifta al-Misriyyah', 'Quran Academy'],
    }, // teacher 2
    {
        image: require("../../Multimedia/Images/ourTeamsPage/islamicMaleTeacher.webp"),
        name: 'Abdullah Khan',
        title: 'Tajweed and Qirat Expert',
        shortDescription: 'Specialist in Tajweed, Quran Memorization, and Ijazah',
        longDescription: [
            'Abdullah Khan has dedicated his life to perfecting and teaching the art of Tajweed and Qirat.',
            'His classes are ideal for students seeking to achieve mastery in Quranic recitation or pursue an Ijazah certification.'
        ],
        age: 39,
        status: 'Single',
        location: 'Birmingham, UK',
        archetype: 'Teacher',
        traits: ['Meticulous', 'Passionate', 'Inspiring'],
        personality: {
            Introvert: 3,
            Extrovert: 7,
            Analytical: 9,
            Creative: 6,
        },
        motivations: {
            Challenge: 9,
            Growth: 8,
            Teamwork: 6,
        },
        frustrations: ['Incorrect Quranic recitation', 'Lack of respect for traditional methods'],
        goals: ['Guide students to perfect Tajweed', 'Help students achieve Ijazah certification'],
        favoriteBrands: ['Jamia Al Karam', 'Qirat Academy', 'Islamic Cultural Centre'],
    },
    {
        image: require("../../Multimedia/Images/ourTeamsPage/islamicFemaleTeacher.webp"),
        name: 'Aisha Suleiman',
        title: 'Noorani Qaida and Children’s Quran Instructor',
        shortDescription: 'Specialist in Quran for Children and Basic Tajweed',
        longDescription: [
            'Aisha Suleiman focuses on early childhood Islamic education, ensuring children develop a strong foundation in their Quranic studies.',
            'Her patient and nurturing approach makes her an excellent instructor for young learners.'
        ],
        age: 28,
        status: 'Married',
        location: 'Lagos, Nigeria',
        archetype: 'Educator',
        traits: ['Nurturing', 'Patient', 'Encouraging'],
        personality: {
            Introvert: 6,
            Extrovert: 4,
            Analytical: 7,
            Creative: 8,
        },
        motivations: {
            Challenge: 7,
            Growth: 8,
            Teamwork: 9,
        },
        frustrations: ['Short attention spans in children', 'Lack of parental involvement'],
        goals: ['Develop engaging Quranic learning resources for children', 'Ensure strong Quranic foundations for all students'],
        favoriteBrands: ['International Open University', 'Islamic Relief', 'Muslim Child Education Foundation'],
    },
    {
        image: require("../../Multimedia/Images/ourTeamsPage/islamicMaleTeacher.webp"),
        name: 'Omar Farooq',
        title: 'Islamic Studies and Hadith Specialist',
        shortDescription: 'Expert in Hadith, Fiqh, and Islamic History',
        longDescription: [
            'Omar Farooq is a renowned expert with a deep understanding of Islamic theology and jurisprudence.',
            'His teaching style is engaging and thought-provoking, making him an excellent guide for students seeking deeper knowledge of Islamic sciences.'
        ],
        age: 42,
        status: 'Married',
        location: 'Istanbul, Turkey',
        archetype: 'Scholar',
        traits: ['Knowledgeable', 'Thoughtful', 'Charismatic'],
        personality: {
            Introvert: 4,
            Extrovert: 6,
            Analytical: 8,
            Creative: 7,
        },
        motivations: {
            Challenge: 8,
            Growth: 9,
            Teamwork: 7,
        },
        frustrations: ['Misunderstanding of Hadith', 'Complexity of Islamic jurisprudence for beginners'],
        goals: ['Write a comprehensive guide on Hadith studies', 'Develop a curriculum for Islamic jurisprudence'],
        favoriteBrands: ['Istanbul University', 'Diyanet İşleri Başkanlığı', 'Islamic Book Trust'],
    },
    {
        image: require("../../Multimedia/Images/ourTeamsPage/islamicFemaleTeacher.webp"),
        name: 'Sarah Ali',
        title: 'Quranic Arabic and Tafsir Instructor',
        shortDescription: 'Specialist in Quranic Arabic and Tafsir',
        longDescription: [
            'Sarah Ali is known for her profound understanding of Quranic language and interpretation.',
            'Her teaching style is both comprehensive and engaging, making her an excellent guide for students seeking to understand the Quran through its language and Tafsir.'
        ],
        age: 35,
        status: 'Single',
        location: 'Mecca, Saudi Arabia',
        archetype: 'Linguist',
        traits: ['Articulate', 'Patient', 'Insightful'],
        personality: {
            Introvert: 5,
            Extrovert: 5,
            Analytical: 8,
            Creative: 6,
        },
        motivations: {
            Challenge: 7,
            Growth: 8,
            Teamwork: 7,
        },
        frustrations: ['Difficulty in teaching non-native speakers', 'Misinterpretations of Tafsir'],
        goals: ['Enhance Quranic Arabic proficiency among students', 'Publish a detailed Tafsir reference guide'],
        favoriteBrands: ['Umm Al-Qura University', 'Quran Academy', 'Islamic University of Medina'],
    },
    {
        image: require("../../Multimedia/Images/ourTeamsPage/islamicMaleTeacher.webp"),
        name: 'Muhammad bin Abdullah',
        title: 'Ijazah Holder and Quran Memorization Expert',
        shortDescription: 'Expert in Tajweed and Quranic Recitation',
        longDescription: [
            'Muhammad bin Abdullah is a respected authority with extensive experience in Quranic recitation and memorization.',
            'He has helped numerous students achieve Ijazah certification and master the art of Quranic recitation.'
        ],
        age: 38,
        status: 'Married',
        location: 'Medina, Saudi Arabia',
        archetype: 'Reciter',
        traits: ['Disciplined', 'Patient', 'Meticulous'],
        personality: {
            Introvert: 3,
            Extrovert: 7,
            Analytical: 9,
            Creative: 6,
        },
        motivations: {
            Challenge: 9,
            Growth: 8,
            Teamwork: 6,
        },
        frustrations: ['Incorrect Tajweed practices', 'Students’ reluctance to commit to memorization'],
        goals: ['Guide students to achieve Ijazah certification', 'Help students master Quranic recitation'],
        favoriteBrands: ['Darul Uloom Deoband', 'Islamic University of Medina', 'Quranic Institute'],
    }
];



