// src/components/SocialButtons.js
import React from 'react';
import {
    EmailShareButton,
} from 'react-share';
import '../../Styles/Components/_navbar0.scss'
import {SocialMediaLinks,PhoneNoCountry} from "../../Data/SocialMedia.";

interface Navbar0Props {
    backgroundGradient: string;
}



const Navbar0: React.FC<Navbar0Props> =  ({ backgroundGradient }) => {

    const title = "Please enter your title of querry"
    const description = "Please write in details your querry to Luminary Quran support "


    const handlePhoneClick = (input: string) => {
        const phoneNumberRegex = /^\+[0-9]{1,3} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (phoneNumberRegex.test(input)) {
            // Input is a phone number, open dialer
            window.location.href = `tel:${input}`;
        } else if (emailRegex.test(input)) {
            // Input is an email address, open Gmail in a new tab
            const url = `mailto:${input}?subject=${title}&body=${description}`;
            window.open(url, '_blank');
        } else {
            // Input is neither a phone number nor an email address, handle error
            console.error('Invalid input');
        }
    };

    return (
        <div className={"MainNavbar0"} style={{ '--background-gradient': backgroundGradient } as React.CSSProperties}>
            <div className={`PhoneCountry`}>
                {PhoneNoCountry.map((country, index:number) => (
                    <div
                        key={index}
                        className="PhoneCountry-item"
                        onClick={() => handlePhoneClick(country.number)}
                    >   {country.image2}
                        <div style={{padding:"5px"}}>{country.number}</div>
                    </div>
                ))}
            </div>


            <div className="social-buttons">

                {/*Facebook Button*/}
                <a href={SocialMediaLinks.facebook} target="_blank" rel="noopener noreferrer" className="custom-button">
                    <button className="custom-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
                        </svg>
                    </button>
                </a>
                {/*Twitter*/}
                <a href={SocialMediaLinks.twitterX} target="_blank" rel="noopener noreferrer" className="custom-button">
                    <button className="custom-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                        </svg>
                    </button>
                </a>
                {/*TIKTOK BUTTON*/}
                <a href={SocialMediaLinks.tiktok} target="_blank" rel="noopener noreferrer" className="custom-button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                    </svg>
                </a>
                {/* Instagram Button */}
                <a href={SocialMediaLinks.instagram} target="_blank" rel="noopener noreferrer" className="custom-button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                    </svg>
                </a>
                {/*Linked in*/}
                <a href={SocialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer" className="custom-button">
                    <button className="custom-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/>
                        </svg>
                    </button>
                </a>
                {/*Messenger Button*/}
                <a href={SocialMediaLinks.fbMessenger} target="_blank" rel="noopener noreferrer" className="custom-button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z"/>
                    </svg>
                </a>
                <a href={`mailto:${SocialMediaLinks.gmail}?subject=${title}&body=${description}`} target="_blank">
                    <button className="custom-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                        </svg>
                    </button>
                </a>
                <a href={`tel:${SocialMediaLinks.phoneNumber}`} className="custom-button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                    </svg>
                </a>
            </div>
        </div>

    );
};

export default Navbar0;

