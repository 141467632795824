import React,{useState} from "react";
import { Formik, Form, Field,FieldProps  } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, MenuItem,Autocomplete, Typography, Box, Modal,  CircularProgress } from '@mui/material';

import {Countries,planTypes,VideoPlatform,Courses} from "../../../Data/Form";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import '../../../Styles/Components/FormStyle/_Form.scss';

import {PhoneNumberInput} from "./PhoneNoCountry"
import ErrorIcon from '@mui/icons-material/Error';
import Select from 'react-select';
import countryList from 'react-select-country-list';


interface SubmissionModalProps {
    open: boolean;
    loading: boolean;
    success: boolean;
    onClose: () => void;
}


const SubmissionModal: React.FC<SubmissionModalProps> = ({ open, loading, success, onClose }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: loading ? '2px solid #0288d1' : success ? '2px solid #388e3c' : '2px solid #d32f2f',
        boxShadow: "100px",
        p:
            4,
        borderRadius:
            '2rem',
        backdropFilter: 'blur(100px)', // Add blur effect
        backgroundColor: loading ? '#e3f2fd' : success ? '#81c784' : '#ffcccc',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="submission-modal-title"
            aria-describedby="submission-modal-description"
        >
            <Box sx={style}>
                {loading ? (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <CircularProgress color="primary" />
                        <Typography variant="h5" mt={2} fontFamily="eBold" sx={{color:"#0288d1"}}>
                            Your DATA is being sent to us
                        </Typography>
                    </Box>
                ) : success ? (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <CheckCircleIcon color="success" fontSize="large" />
                        <Typography variant="h5" mt={2} fontFamily="eBold">
                            We have received your data
                        </Typography>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <ErrorIcon color="error" fontSize="large"/>
                        <Typography variant="h4" mt={2} fontFamily="eBold" sx={{color:"#d32f2f"}}>
                            ERROR sending data
                        </Typography>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};


const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required('Full name is required')
        .min(2, 'Full name must be at least 2 characters long')
        .max(50, 'Full name must be at most 50 characters long')
        .matches(/^[A-Za-z\s]+$/, 'Full name must only contain letters and spaces'),
    videoPlatform:Yup.string()
        .required('Select some Platform'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(
            /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/,
            'Phone number is not valid'
        ),
    planType: Yup.string()
        .required('Plan type is required'),
    courses: Yup.string()
        .required('Select a course'),
    country: Yup.string()
        .required('Country is required'),
    city: Yup.string()
        .required('City is required')
        .min(2, 'City name must be at least 2 characters long')
        .max(50, 'City name must be at most 50 characters long')
        .matches(/^[A-Za-z\s]+$/, 'City name must only contain letters and spaces'),
    message: Yup.string(),
});

const RegistrationForm: React.FC = () => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const handleClose = () => setOpen(false);

    return (
        <div className={"formContainer"}>
        <Box className="registration-form">
            <Typography variant="h4" className="form-heading">
                REGISTOR YOUR DETAILS
            </Typography>
            <Formik
                initialValues={{
                    fullName: '',
                    videoPlatform: '',
                    email: '',
                    phoneNumber: '',
                    planType: '',
                    courses:'',
                    country: '',
                    city: '',
                    message: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values: any, { setSubmitting, resetForm }: any) => {
                    setSubmitting(true);
                    setLoading(true);
                    setOpen(true);

                    const maxRetries: number = 3;
                    let retryCount: number = 0;

                    const sendData = async (): Promise<boolean> => {
                        try {
                            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/enrollment/`, values, {
                                timeout: 10000, // 10 seconds timeout
                            });
                            // console.log('Data sent successfully:', response.data);
                            setSuccess(true);
                            resetForm();
                            return true;
                        } catch (error: any) {
                            // console.error('Error sending data:', error);
                            if (retryCount < maxRetries) {
                                retryCount++;
                                // console.log(`Retrying... Attempt ${retryCount}`);
                                await new Promise(resolve => setTimeout(resolve, 2000 * retryCount)); // Exponential backoff
                                return sendData();
                            }
                            setSuccess(false);
                            return false;
                        }
                    };

                    sendData().then((success: boolean) => {
                        setSubmitting(false);
                        setLoading(false);
                        setTimeout(() => {
                            setOpen(false);
                            if (success) {
                                setSuccess(false);
                            }
                        }, 3000);
                    });
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div className="form-grid">
                            <div className="form-item">
                                <Field
                                    as={TextField}
                                    name="fullName"
                                    label="Full Name"
                                    fullWidth
                                    error={!!(touched.fullName && errors.fullName)}
                                    helperText={touched.fullName && errors.fullName ? errors.fullName : ''}

                                />
                            </div>
                            <div className="form-item">
                                <Field
                                    as={TextField}
                                    select
                                    name="videoPlatform"
                                    label="Video Platform"
                                    fullWidth
                                    error={!!(touched.videoPlatform && errors.videoPlatform)}
                                    helperText={touched.videoPlatform && errors.videoPlatform ? errors.videoPlatform : ''}>
                                    {VideoPlatform.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </div>
                            <div className="form-item">
                                <Field
                                    as={TextField}
                                    name="email"
                                    label="Enter your Email"
                                    fullWidth
                                    error={!!(touched.email && errors.email)}
                                    helperText={touched.email && errors.email ? errors.email:''}
                                    disabled={!touched.videoPlatform }
                                />
                            </div>
                            <div className="form-item">
                                <Field name="phoneNumber" >
                                    {({ field }: FieldProps) => (
                                        <PhoneNumberInput
                                            value={field.value}
                                            onChange={(value) => setFieldValue('phoneNumber', value)}
                                            error={!!(errors.phoneNumber && touched.phoneNumber)}
                                            touched={touched.phoneNumber}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="form-item">
                                <Field
                                    as={TextField}
                                    select
                                    name="planType"
                                    label="Plan Type"
                                    fullWidth
                                    error={!!(touched.planType && errors.planType)}
                                    helperText={touched.planType && errors.planType ?errors.planType:''}
                                >
                                    {planTypes.map((option) => (
                                        <MenuItem key={option.label} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </div>
                            <div className="form-item">
                                <Field
                                    as={TextField}
                                    select
                                    name="courses"
                                    label="Course Selection"
                                    fullWidth
                                    error={!!(touched.courses && errors.courses)}
                                    helperText={touched.courses && errors.courses ? errors.courses : ''}
                                >
                                    {Courses.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </div>
                            <div className="form-item">
                                <Field name="country">
                                    {({ field }: any) => (
                                        <Autocomplete
                                            {...field}
                                            options={Countries.map((option) => option.label)}
                                            onInputChange={(_, value) => setFieldValue('country', value)}  // For typed values
                                            onChange={(_, value) => setFieldValue('country', value)}  // For dropdown selections
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Country"
                                                    fullWidth
                                                    error={!!(touched.country && !!errors.country)}
                                                    helperText={touched.country && errors.country}
                                                />
                                            )}
                                            freeSolo  // Allows free typing
                                            isOptionEqualToValue={(option, value) => option === value}
                                        />
                                    )}
                                </Field>
                            </div>


                            <div className="form-item">
                                <Field
                                    as={TextField}
                                    name="city"
                                    label="City"
                                    fullWidth
                                    error={!!(touched.city && errors.city)}
                                    helperText={touched.city && errors.city ? errors.city : ''}
                                />
                            </div>

                            <div className="form-item full-width">
                                <Field
                                    as={TextField}
                                    name="message"
                                    label="Any message for us"
                                    fullWidth/>
                            </div>
                        </div>

                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
            <SubmissionModal open={open} loading={loading} success={success} onClose={handleClose} />
        </Box>
    </div>
    );
};

export default RegistrationForm;