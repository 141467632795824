// ImportanceCarousel.tsx
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import "../../../Styles/Components/_Carousal.scss";

const LazyCarousel = lazy(() => import('react-responsive-carousel').then(module => ({ default: module.Carousel })));

interface CarouselItem {
    text: React.ReactElement;
    image: string;
}

const CAROUSEL_ITEMS = () => import("../../../Data/HomePageData/course_carousalData");

const CarouselSkeleton = () => (
    <Skeleton variant="rectangular" width="100%" height={400} />
);

const ImportanceCarousel: React.FC = () => {
    const [items, setItems] = useState<CarouselItem[]>([]);

    useEffect(() => {
        CAROUSEL_ITEMS().then((module) => {
            setItems(module.default);
        });
    }, []);

    return (
        <div className="carousel-container">
            <Suspense fallback={<CarouselSkeleton />}>
                {items.length > 0 && (
                    <LazyCarousel
                        infiniteLoop
                        autoPlay
                        interval={8000}
                        showStatus={false}
                        showThumbs={false}
                        dynamicHeight={false}
                        className="carousel-slider"
                        swipeable={false}
                        emulateTouch={false}

                    >
                        {items.map(({  text, image }, index) => (
                            <div key={index} className="carousel-slide">
                                <img src={image} className="carousel-image" alt="image_" loading="lazy" />
                                <div className="carousel-heading">
                                </div>
                                 {text}
                            </div>
                        ))}
                    </LazyCarousel>
                )}
            </Suspense>
        </div>
    );
}

export default ImportanceCarousel;
