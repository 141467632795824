interface PlanTypeInterface {
    label:string
}

interface CountriesInterface {
    label:string
}

interface VideoPlatformInterface {
    label:string
    value:string

}


const planTypes:PlanTypeInterface[] = [
    { label: 'Get a FREE 2-day Trial' },
    { label: '2 D/Week - 1 Month   $38/£28' },
    { label: '3 D/Week - 1 Month   $55/£39' },
    { label: '4 D/Week - 1 Month   $70/£50' },
    { label: '5 D/Week - 1 Month   $86/£59' },
    { label: '3 D/Week - 6 Months  $313/£222 (PLUS)' },
    { label: '4 D/Week - 12 Months $781/£558 (PRO)' },
    { label: '5 D/Week - 12 Months $928/£637 (ELITE)' },

];

const VideoPlatform: VideoPlatformInterface[] = [
    { value: "https://zoom.us", label: 'Zoom' },
    { value: "https://meet.google.com", label: 'Google Meet' },
    { value: "https://teams.microsoft.com", label: 'Microsoft Teams' },
    { value: "https://www.skype.com", label: 'Skype' },


];

const Countries:CountriesInterface[] = [
    {'label': 'United States'},
    {'label': 'United Kingdom'},
    {'label': 'Canada'},
    {'label': 'Australia'},
    {'label': 'Germany'},
    {'label': 'France'},
    {'label': 'Italy'},
    {'label': 'Spain'},
    {'label': 'Netherlands'},
    {'label': 'Sweden'},
    {'label': 'Switzerland'},
    {'label': 'Belgium'},
    {'label': 'Norway'},
    {'label': 'Denmark'},
    {'label': 'Finland'},
    {'label': 'Austria'},
    {'label': 'Ireland'},
    {'label': 'Portugal'},
    {'label': 'Greece'},
    {'label': 'Poland'},
    {'label': 'Brazil'},
    {'label': 'Argentina'},
    {'label': 'Mexico'},
    {'label': 'Chile'},
    {'label': 'China'},
    {'label': 'Japan'},
    {'label': 'South Korea'},
    {'label': 'India'},
    {'label': 'Pakistan'},
    {'label': 'Indonesia'},
    {'label': 'Russia'},
    {'label': 'Saudi Arabia'},
    {'label': 'South Africa'},
    {'label': 'Egypt'},
    {'label': 'Nigeria'},
    {'label': 'Turkey'},
    {'label': 'Singapore'},
    {'label': 'Thailand'},
    {'label': 'Vietnam'},
    {'label': 'Philippines'},
    {'label': 'New Zealand'},
    {'label': 'Malaysia'},
    {'label': 'United Arab Emirates'},
    {'label': 'Qatar'},
    {'label': 'Jordan'},
    {'label': 'Kuwait'},
    {'label': 'Iraq'},
    {'label': 'Iran'}

]

const Courses: string[] = [
    "Noorani Qaida(KIDS)",
    "Reading Quran",
    "Quran Memorization",
    "Tafsir Course",
    "Ten Qirat Courses",
    "Basic/Advanced Tajweed Courses",
    "Online Ijazah Program",
    "Arabic Language Courses",
    "Islamic Studies",
    "Islamic Supplications",
    "Prayer/Hadith lessons",

];


export {planTypes,Countries,VideoPlatform,Courses}