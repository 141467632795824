// TestimonialsData.ts
interface TestimonialDataInterface {
    userName: string;
    userImage: string;
    userTitle: string;
    userFeedback: string;
    userRating: number;
}

const testimonialsData:TestimonialDataInterface[] = [

    { // testimoinals 1
        userName: "Fatima Ilyas",
        userImage: require("../../Multimedia/Images/TestimonialsPage/FemaleFeedback.webp"),
        userTitle: "Birmingham, West Midlands, UK",
        userFeedback: "Excited with my children's advancement in Quranic schooling, because of devoted educators!",
        userRating: 4.23,
    },
    {
        userName: "Ali Kazmi",
        userImage: require("../../Multimedia/Images/TestimonialsPage/maleFeedback.webp"),
        userTitle: "Vancouver, English Columbia, Canada",
        userFeedback: "Adaptable web-based classes and astounding instructors assisted me with working on my recitation!",
        userRating: 4.56,
    },
    {
        userName: "Humaira Abbasi",
        userImage: require("../../Multimedia/Images/TestimonialsPage/FemaleFeedback.webp"),
        userTitle: "Melbourne, Victoria, Australia",
        userFeedback: "Patient and proficient teachers assisted me with interfacing with the Quran on a more profound level!",
        userRating: 4.89,
    },
    {
        userName: "Huzaifah Anjum",
        userImage: require("../../Multimedia/Images/TestimonialsPage/maleFeedback.webp"),
        userTitle: "New York City, New York, USA",
        userFeedback: "Very much organized examples assisted me with understanding the Quran better, Alhamdulillah!",
        userRating: 4.12,
    },
    {
        userName: "Abidah Marukh",
        userImage: require("../../Multimedia/Images/TestimonialsPage/FemaleFeedback.webp"),
        userTitle: "Chicago, Illinois, USA",
        userFeedback: "Individual consideration and steady instructors assisted me with beating my difficulties!",
        userRating: 4.67,
    },
    {
        userName: "Waseem Malick",
        userImage: require("../../Multimedia/Images/TestimonialsPage/maleFeedback.webp"),
        userTitle: "London, More noteworthy London, UK",
        userFeedback: "Drawing in web-based courses, steady local area, and pleasant examples - how could I possibly want anything more?",
        userRating: 4.34,
    },
    {
        userName: "Moiz Hasnain",
        userImage: require("../../Multimedia/Images/TestimonialsPage/maleFeedback.webp"),
        userTitle: "Toronto, Ontario, Canada",
        userFeedback: "Energetic educators and a strong climate assisted me with developing in a deep sense!",
        userRating: 4.98,
    },
    {
        userName: "Saad Nazir",
        userImage: require("../../Multimedia/Images/TestimonialsPage/maleFeedback.webp"),
        userTitle: "Los Angeles, Southern California, USA",
        userFeedback: "Adaptable internet based stage and strong instructors assisted me with adjusting my obligations!",
        userRating: 4.41,
    },
    {
        userName: "Aiman Batool",
        userImage: require("../../Multimedia/Images/TestimonialsPage/FemaleFeedback.webp"),
        userTitle: "Sydney, New South Grains, Australia",
        userFeedback: "Strong climate and astonishing instructors made my Quranic schooling venture agreeable!",
        userRating: 4.75,
    },
];

export type {TestimonialDataInterface}
export {testimonialsData}
