import React, { lazy, Suspense, useState, memo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Divider, Skeleton } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

// Lazy-loaded components
const LazyArrowForwardIosSharpIcon = lazy(() => import('@mui/icons-material/ArrowForwardIosSharp'));
const LazyHelpOutlineIcon = lazy(() => import('@mui/icons-material/HelpOutline'));

const StyledAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': { borderBottom: 0 },
    '&::before': { display: 'none' },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={
            <Suspense fallback={<div style={{ width: '0.9rem', height: '0.9rem' }} />}>
                <LazyArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
            </Suspense>
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)' },
    '& .MuiAccordionSummary-content': { marginLeft: theme.spacing(1) },
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface FAQItemInterface {
    Heading: string;
    AccordionDetails: string;
}

interface CustomizedAccordionsProps {
    FaqData: FAQItemInterface[];
}

const FAQItem = memo(({ item, panelName, expanded, onChange }:
                          { item: FAQItemInterface; panelName: string; expanded: boolean; onChange: (event: React.SyntheticEvent, expanded: boolean) => void }) => (
    <StyledAccordion expanded={expanded} onChange={onChange}>
        <StyledAccordionSummary aria-controls={`${panelName}d-content`} id={`${panelName}d-header`}>
            <Typography sx={{ fontFamily: 'Reg' }}>{item.Heading}</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
            <Typography sx={{ fontFamily: 'Thin',textAlign:"left",padding:"0rem 1.5rem 0rem 1.5rem" }}>{item.AccordionDetails}</Typography>
        </StyledAccordionDetails>
    </StyledAccordion>
));

const CustomizedAccordions: React.FC<CustomizedAccordionsProps> = ({ FaqData }) => {
    const [expanded, setExpanded] = useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Typography sx={{ fontFamily: 'eMed', marginTop: '4rem', marginBottom: '2rem' }} variant="h3" gutterBottom>
                FREQUENTLY ASKED QUESTION
                <Suspense fallback={<div style={{ width: '50px', height: '50px', display: 'inline-block' }} />}>
                    <LazyHelpOutlineIcon style={{ fontSize: 50, color: 'gray' }} />
                </Suspense>
            </Typography>
            <Divider />
            <Suspense fallback={<FAQSkeleton count={FaqData.length} />}>
                {FaqData.map((item, index) => {
                    const panelName = `panel${index + 1}`;
                    return (
                        <FAQItem
                            key={panelName}
                            item={item}
                            panelName={panelName}
                            expanded={expanded === panelName}
                            onChange={handleChange(panelName)}
                        />
                    );
                })}
            </Suspense>
        </div>
    );
};

const FAQSkeleton: React.FC<{ count: number }> = memo(({ count }) => (
    <>
        {[...Array(count)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={70} sx={{ my: 1 }} />
        ))}
    </>
));

export default memo(CustomizedAccordions);