import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Multimedia/Logo/MainLogo.webp';
import '../../Styles/Components/_navbar1.scss';

const Navbar1: React.FC = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isExploreOpen, setIsExploreOpen] = useState(false);
    const [isMounted, setIsMounted] = useState(false);  // New state to handle initial mounting

    const menuRef = useRef<HTMLUListElement | null>(null);
    const example8Ref = useRef<HTMLAnchorElement | null>(null);

    const handleClick = () => {
        if (example8Ref.current) {
            if (isMobileMenuOpen) {
                // Remove the active class and add transition class
                example8Ref.current.classList.add('active-transition');
                setTimeout(() => {
                    example8Ref.current?.classList.remove('active');
                    example8Ref.current?.classList.remove('active-transition');
                }, 200); // match the duration of the transition
            } else {
                // Add the active class
                example8Ref.current.classList.add('active');
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => setIsScrolled(window.scrollY > 0);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (isMobileMenuOpen) {
            setIsMounted(true);  // Set the mounted state to true to trigger visibility

            // Apply transition effects after a short delay
            setTimeout(() => {
                if (menuRef.current) {
                    menuRef.current.style.opacity = '1';
                    menuRef.current.style.transform = 'translateY(0)';
                }
            }, 50); // Adjust delay as needed
        } else {
            if (menuRef.current) {
                menuRef.current.style.opacity = '0';
                menuRef.current.style.transform = 'translateY(-10px)';
            }

            // Delay hiding the component to allow the transition to complete
            setTimeout(() => setIsMounted(false), 300); // Match this delay with your CSS transition duration
        }
    }, [isMobileMenuOpen]);

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
    const toggleExploreMenu = () => setIsExploreOpen(!isExploreOpen);

    const navbarItems = [
        { path: '/', text: 'Home' },
        { path: '/Courses', text: 'Courses' },
        { path: '/Plans', text: 'Plan' },
    ];

    const exploreItems = [
        { text: "Our Team", link: "/OurTeam" },
        { text: "Our Policy", link: "/OurPolicy" },
        { text: "Testimonial", link: "/Testimonials" },
        { text: "About Us⠀", link: "/AboutUs" },
    ];

    return (
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <div className="navbar-content">
                <Link to="/" className="navbar-brand">
                    <img src={logo} alt="Company Logo" className="navbar-logo" />
                    <div className="navbarTitleX1">Luminary Quran</div>
                </Link>
                <ul className="navbar-links">
                    {navbarItems.map((item) => (
                        <li key={item.path}>
                            <Link to={item.path} className="navbar-link" data-text={item.text}>
                                {item.text}
                            </Link>
                        </li>
                    ))}
                    <li className="navbar-dropdown">
                        <Link to="#" className="navbar-link" data-text="Explore" onClick={toggleExploreMenu}>
                            Explore
                        </Link>
                        <ul className={`navbar-dropdown-menu ${isExploreOpen ? 'open' : ''}`}>
                            {exploreItems.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.link} className="navbar-dropdown-link" data-text={item.text}>
                                        {item.text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
                <a href="#"
                   className={`MobileNavBarIcon example8 ${isMobileMenuOpen ? 'active' : ''}`}
                   ref={example8Ref}
                   onClick={() => {
                       toggleMobileMenu();
                       handleClick();
                   }}
                ><span></span></a>
            </div>
            {isMounted && (  // Only mount the menu if isMounted is true
                <ul
                    ref={menuRef}
                    className="navbar-mobile-menu"
                    style={{
                        transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                    }}
                >
                    {navbarItems.map((item) => (
                        <li key={item.path}>
                            <Link to={item.path} className="navbar-mobile-menu-link" data-text={item.text} onClick={toggleMobileMenu}>
                                {item.text}
                            </Link>
                        </li>
                    ))}
                    <Link to="#" className="navbar-mobile-menu-link" data-text="Explore" onClick={toggleExploreMenu}>
                        Explore
                    </Link>
                    {isExploreOpen && (
                        <ul className="navbar-mobile-dropdown-menu" style={{ listStyle: "none", padding: "0" }}>
                            {exploreItems.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.link} className="navbar-mobile-dropdown-link" data-text={item.text} onClick={() => { toggleExploreMenu(); toggleMobileMenu(); }} >
                                        {item.text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </ul>
            )}
        </nav>
    );
};

export default Navbar1;
