import React from 'react';
import "../../Styles/Components/_Main_Button.scss"
import { scroller } from 'react-scroll';

const GradientButton = ({  text = 'Click Me' }) => {
    const handleClick = () => {
        scroller.scrollTo('XXXX', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -70,
        });
    };

    return (

        <button className="gradient-button"  onClick={handleClick}>

            {text}
        </button>
    );
};

export default GradientButton;
