import React from 'react';
import {Box, Typography, Grid, Divider} from '@mui/material';
import { styled } from '@mui/system';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import ArticleIcon from '@mui/icons-material/Article';
// import GetAppIcon from '@mui/icons-material/GetApp';
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

interface CourseIncludesItem {
    icon: React.ReactNode;
    text: string;
}

interface CourseIncludesProps {
    data: CourseIncludesItem[];
}

const StyledBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: 'rgba(220,220,220,0.61)',
    borderRadius:"2rem",
    margin:"2rem 0rem 2rem 0rem"
}));

const CourseIncludes: React.FC<CourseIncludesProps> = ({ data }) => {
    return (
        <StyledBox>
                <Typography  sx={{ fontFamily: 'eMed' ,marginTop:'3rem',marginBottom:'2rem'}} variant="h4" gutterBottom>
                THIS COURSE INCLUDES
                <Divider sx={{marginTop:1.5}} />
            </Typography>
            <Grid container spacing={2}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Box display="flex" alignItems="center">
                            {item.icon}
                            <Typography variant="body1" sx={{ fontFamily: 'Thin', ml:2, mt:1, textAlign:"left"}}>
                                {item.text}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </StyledBox>
    );
};

export default CourseIncludes;