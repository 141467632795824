interface FaqDataInterface {
    Heading: string,
    AccordionDetails:string

}

const faqHomeData: FaqDataInterface[] = [

    {
        Heading: "What Exciting courses does Luminary Quran offer?",
        AccordionDetails:
            "We enlighten the way to Quranic information with complete courses in Quran ranging from Noorani Qaida(KIDS), Reading Quran, Quran Memorization, Tafsir Course, Ten Qirat Courses, Basic/Advanced Tajweed Courses, Online Ijazah Program, Arabic Language Courses, Islamic Studies, Islamic Supplications,Prayer/Hadith lessons, taking care of any age and levels.",
    },
    {
        Heading: "Who are the regarded educators?",
        AccordionDetails:
            "At Luminary Quran, The educators/teachers are exceptionally well qualified with broad mastery in Quranic studies, devoted to directing you on your Spiritual Journey.",
    },
    {
        Heading: "Is your foundation a sustaining environment for kids?",
        AccordionDetails:
            "Totally! We offer compelling,communicative and age-proper Quran courses explicitly intended for kids, cultivating areas of strength for a for their Religious nurturing.",
    },
    {
        Heading: "Could I at any point reach out to Luminary Quran for learning for Tawjeed courses?",
        AccordionDetails:
            "Indeed, we offer internet based Quran classes with Tajweed for the two adults and youngsters ensuring flexibility, adaptability and accommodation to your respective needs in regards to the course.",
    },
    {
        Heading: "How would I sign up for a course and start my Quranic venture?",
        AccordionDetails:
            "Enlist by finishing up the Registration Form at the bottom of any page on our website. You may contact on our any socail network link so you can directly connect with us.",
    },
    {
        Heading: "What is your refund policy/strategy, on the off chance that I really want to drop?",
        AccordionDetails:
            "We comprehend that conditions might emerge, and we offer discounts in view of our strategy, which incorporates arrangements for paid occasions like Eid, guaranteeing a fair and adaptable methodology. For more information refer to our refund policy on Our Policy page.",
    },
    {
        Heading: "What separates you from other (variable) Platforms that offer the same services?",
        AccordionDetails:
            "We give customized, excellent Quranic training by expert teachers. We are adaptable to new technologies and platforms ( currently we offer 4 video platforms), making us a flexible yet resilient in achieving objective of spreading divine knowledge.",
    },
];


export type {FaqDataInterface}
export{faqHomeData}