    // TeamMemberDetail.tsx
    import React from 'react';
    import {Box, Typography, Paper, Grid, Chip, LinearProgress, useMediaQuery,ThemeProvider} from '@mui/material';
    import { TeamMember } from '../../../Data/OurTeamPageData/ourTeamPageData';
    import { createTheme } from '@mui/material/styles';
    interface TeamMemberDetailProps {
        member: TeamMember;
    }

    const theme = createTheme({
        palette: {
            primary: {
                light: '#FFD700',
                main: '#ff7300',
                dark: '#ff2600',
            },
            secondary: {
                main: '#FF4500',
                light: 'rgba(255, 153, 0,0.1)'


            },
        },
        typography: {
            fontFamily: 'Thin',
            fontSize: 14,
            h5:{
                fontFamily:'eBold'
            },
            h6:{
                fontFamily:'eBold'
            },
            body2:{
                fontFamily:'Thin'
            },
            subtitle1:{
                fontFamily:'eMed'
            },


        },


    });
    const TeamMemberDetail: React.FC<TeamMemberDetailProps> = ({ member }) => {
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));

        return (
            <ThemeProvider theme={theme}>
            <Box sx={{
                background: 'linear-gradient(90deg, #FF4500 0%, #FF5700 15%, #FF8C00 30%, #FFA500 45%, #FFA900 50%, #FFA500 55%, #FF8C00 70%, #FF5700 85%, #FF4500 100%);',
                        p: 3, borderRadius: "2rem", padding:isMobile?2:"3rem 2rem 3rem 2rem" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: "1rem", borderRadius:"2rem",transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box
                                    component="img"
                                    src={member.image}
                                    alt={member.name}
                                    sx={{ width: 200, height: 200, borderRadius: '50%', mb: 2 }}
                                />
                                <Typography variant="h5">{member.name}</Typography>
                                <Typography variant="subtitle1" color="text.secondary">{member.title} </Typography>
                                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>"{member.shortDescription}"</Typography>
                            </Box>
                            <Box sx={{ mt: 2,display:"flex",flexDirection:"column",alignItems: "center"}}>
                                <Typography variant="body2">
                                    <Box component="span" sx={{  fontFamily:'Bold' }}>Age: </Box>
                                    {member.age}
                                </Typography>
                                <Typography variant="body2">
                                    <Box component="span" sx={{  fontFamily:'Bold' }}>Status: </Box>
                                    {member.status}
                                </Typography>
                                <Typography variant="body2">
                                    <Box component="span" sx={{  fontFamily:'Bold' }}>Location: </Box>
                                    {member.location}
                                </Typography>
                                <Typography variant="body2">
                                    <Box component="span" sx={{  fontFamily:'Bold' }}>Archetype: </Box>
                                    {member.archetype}
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                {member.traits?.map((trait, index) => (
                                    <Chip key={index} label={trait} sx={{ mr: 1, mb: 1 }} />
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{ p: "1rem", mb: 2,borderRadius:"2rem",transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                            <Typography variant="h6">Bio</Typography>
                            {member.longDescription.map((para, index) => (
                                <Typography key={index} variant="body2" paragraph>
                                    {para}
                                </Typography>
                            ))}
                        </Paper>
                        <Paper elevation={3} sx={{ p: "1rem", mb: 2,borderRadius:"2rem",transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                            <Typography variant="h6">Personality</Typography>
                            {Object.entries(member.personality || {}).map(([trait, value]) => (
                                <Box key={trait} sx={{ mb: 1 }}>
                                    <Typography variant="body2">{trait}</Typography>
                                    <LinearProgress variant="determinate" value={value * 10} />
                                </Box>
                            ))}
                        </Paper>
                        <Paper elevation={3} sx={{ p: 2, mb: 2,borderRadius:"2rem",transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                            <Typography variant="h6">Motivations</Typography>
                            {Object.entries(member.motivations || {}).map(([motivation, value]) => (
                                <Box key={motivation} sx={{ mb: 1 }}>
                                    <Typography variant="body2">{motivation}</Typography>
                                    <LinearProgress variant="determinate" value={value * 10} color="secondary" />
                                </Box>
                            ))}
                        </Paper>
                        <Paper elevation={3} sx={{ p: 2,borderRadius:"2rem",transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                            <Typography variant="h6">Frustrations</Typography>
                            <ul>
                                {member.frustrations?.map((frustration, index) => (
                                    <li key={index}><Typography variant="body2">{frustration}</Typography></li>
                                ))}
                            </ul>
                            <Typography variant="h6">Goals</Typography>
                            <ul>
                                {member.goals?.map((goal, index) => (
                                    <li key={index}><Typography variant="body2">{goal}</Typography></li>
                                ))}
                            </ul>
                            <Typography variant="h6">Favourite Brands</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                {member.favoriteBrands?.map((brand, index) => (
                                    <Chip key={index} label={brand} />
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            </ThemeProvider>
        );
    };

    export default TeamMemberDetail;