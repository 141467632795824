// TestimonialsPage.tsx
import React,{lazy,Suspense} from 'react';
import {Container, Grid, Typography, Box, Divider} from '@mui/material';
import {keyframes} from '@mui/system';
import {testimonialsData} from "../Data/TestimonialsPageData/TestimonialsPageData";
import {Element} from "./ComponentsExports"


const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const TestimonialCard = lazy( () => import('./ComponentsExports').then(module => ({ default: module.TestimonialCard })));


const Testimonials: React.FC = () => {
    return (
        <div>
        <Container>
                <Typography sx={{ fontFamily: 'eBold', marginTop:'2rem', marginBottom:'2rem',wordWrap: 'break-word' }} variant="h4"  gutterBottom>
                    TESTIMONIALS
                    <Divider textAlign={"left"}  sx={{fontFamily:"Thin",fontSize:"0.8rem"}} >TESTIMONIALS SECTION</Divider>
                </Typography>
        </Container>

        <Box
            sx={{
                background: 'linear-gradient(90deg, #FF4500 0%, #FF5700 15%, #FF8C00 30%, #FFA500 45%,  #FFA500 55%, #FF8C00 70%, #FF5700 85%, #FF4500 100%);',
                backgroundSize: '500% 500%',
                animation: `${gradientAnimation} 10s ease infinite`,
                borderRadius: "1rem",
                minHeight: '100vh', py: 8
        }}>
            <Container>
                <Element name="OurClientsFooter">
                <Typography
                    variant="h3"
                    sx={{
                        textAlign: 'center',
                        mb: 6,
                        color: '#FFFFFF',
                        fontFamily: 'eBold',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                    }}
                >
                    OUR STUDENT'S SUCCESS IS OUR PRIDE
                </Typography>
                </Element>
                <Grid container spacing={4} justifyContent="center">
                    {testimonialsData.map((testimonial, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <Suspense fallback={<div>...loading</div>}>
                                <TestimonialCard testimonial={testimonial}/>
                            </Suspense>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
            <Divider textAlign={"right"}  sx={{fontFamily:"Thin",fontSize:"0.8rem"}} >TESTIMONIALS  </Divider>

        </div>
    );
};


export default Testimonials;
