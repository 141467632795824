import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContentItem {
    icon: React.ReactNode;
    subtitle: string;
    time: string;
}

interface AccordionData {
    title: string;
    content: ContentItem[];
    totalLectures: string;
    totalDuration: string;
}

interface ContentAccordionProps {
    data: AccordionData[];
}


const theme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    textAlign:'left',
                    padding: "0rem 0.5rem 0rem 1.5rem",
                    '&:before': {
                        display: 'none',
                    },
                    '&.Mui-expanded': {
                        margin: '8px 0',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                    color: 'inherit',
                    transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
                    '&.Mui-expanded': {
                        backgroundColor: 'orange',
                        color: 'white',
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        color: 'inherit',
                        transition: 'color 0.3s ease-in-out',
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: 'Thin',
        h4: {
            fontFamily: 'eMed',
        },
        subtitle1: {
            fontFamily: 'eBold',
        },
    },
});

const ContentAccordion: React.FC<ContentAccordionProps> = ({ data }) => {
    return (
        <ThemeProvider theme={theme}>
            <Typography variant="h4" gutterBottom sx={{ margin: '2rem 1rem 2rem 1rem' }}>
                COURSE CONTENTS
                <Divider sx={{ marginTop: 1.5 }} />
            </Typography>
            {data.map((item, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                            {item.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {item.totalLectures} lectures • {item.totalDuration}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {item.content.map((contentItem, contentIndex) => (
                                <ListItem key={contentIndex}>
                                    <ListItemIcon>{contentItem.icon}</ListItemIcon>
                                    <ListItemText primary={contentItem.subtitle} />
                                    <ListItemSecondaryAction>
                                        <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                            {contentItem.time}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
        </ThemeProvider>
    );
};

export { ContentAccordion };