import React from 'react';
import '../../../Styles/Components/_CurrencyCard.scss';
import { ScrollLink } from "../../ComponentsExports"

interface CurrencyCardProps {
        title: string;
        price: string;
        currency: string;
        features: string[];
        buttonText: string;
}

const CurrencyCard: React.FC<CurrencyCardProps> = ({ title, price, currency, features, buttonText }) => {
        return (
            <div className="card">
                    <div className="card-header">{title}</div>
                    <div className="card-price">{price}</div>
                    <div className="card-currency">{currency}</div>
                    <div className="card-features">
                            {features.map((feature: string, index: number) => (
                                <div key={index} className="card-feature">
                                        {feature}
                                </div>
                            ))}
                    </div>
                    <ScrollLink to={"XXXX"} pagePath={"/"}>
                            <button className="card-button">{buttonText}</button>
                    </ScrollLink>
            </div>
        );
}

export default React.memo(CurrencyCard);