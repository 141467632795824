import { useState, useEffect } from 'react';
import {FaqDataInterface} from "../Data/HomePageData/FAQ_homeData";
import {PricesInterface} from "../Data/PlanPageData/PlanPageData";
import {TestimonialDataInterface} from "../Data/TestimonialsPageData/TestimonialsPageData";
import {WhyUsCardsInterface} from "../Data/WhyUsCards";
import {ImportancePointsInterface} from "../Data/HomePageData/Introduction_&_QuranImportanceData"

interface AllData {
    PricesMonthlyData: PricesInterface[];
    faqHomeData: FaqDataInterface[];
    whyUsCards: WhyUsCardsInterface[];
    testimonialsData: TestimonialDataInterface[];
    importancePoints: ImportancePointsInterface[];
    introduction: React.ReactElement;
}

// Custom hook

// We'll use this to ensure we only fetch once
let isDataFetched = false;
let globalData: AllData | null = null;
let globalError: Error | null = null;

export const useBackgroundDataFetch = () => {
    const [DATA, setData] = useState<AllData | null>(globalData);
    const [isLoading, setIsLoading] = useState(!isDataFetched);
    const [error, setError] = useState<Error | null>(globalError);

    useEffect(() => {
        if (isDataFetched) {
            // Data has already been fetched, just update local state
            setData(globalData);
            setIsLoading(false);
            setError(globalError);
            return;
        }

        const fetchData = async () => {
            try {
                // Dynamically import modules
                const [
                    homeData,
                    whyUsCardsData,
                    testimonialsPageData,
                    normalData,
                    PlanData
                ] = await Promise.all([
                    import("../Data/HomePageData/FAQ_homeData"),
                    import("../Data/WhyUsCards"),
                    import("../Data/TestimonialsPageData/TestimonialsPageData"),
                    import("../Data/HomePageData/Introduction_&_QuranImportanceData"),
                    import("../Data/PlanPageData/PlanPageData")
                ]);

                const fetchedData: AllData = {
                    PricesMonthlyData: PlanData.PricesMonthlyData,
                    faqHomeData: homeData.faqHomeData,
                    whyUsCards: whyUsCardsData.WhyUsCards,
                    testimonialsData: testimonialsPageData.testimonialsData,
                    importancePoints: normalData.importancePoints,
                    introduction: normalData.Introduction
                };

                globalData = fetchedData;
                setData(fetchedData);
                setIsLoading(false);
                isDataFetched = true;
            } catch (err) {
                const errorObj = err instanceof Error ? err : new Error('An error occurred while fetching data');
                globalError = errorObj;
                setError(errorObj);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return { DATA, isLoading, error };
};