import React, { useState, useRef, useEffect, Suspense, lazy } from 'react';
import {
    Box,
    Divider,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Element } from 'react-scroll';
import { styled } from '@mui/system';
import { CourseData } from '../Data/CoursePageData/CoursePageData';

// Lazy load components
const CourseHeader = lazy(() => import('./ComponentsExports').then(module => ({ default: module.CourseHeader })));
const CourseImage = lazy(() => import('./ComponentsExports').then(module => ({ default: module.CourseImage })));
const LearningCard = lazy(() => import('./ComponentsExports').then(module => ({ default: module.LearningCard })));
const CourseIncludes = lazy(() => import('./ComponentsExports').then(module => ({ default: module.CourseIncludes })));
const InstructorPage = lazy(() => import('./ComponentsExports').then(module => ({ default: module.InstructorPage })));
const ScrollLink = lazy(() => import('./ComponentsExports').then(module => ({ default: module.ScrollLink })));


const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '1rem',
    margin: '0',
    textAlign: 'center',
    background: 'linear-gradient(90deg, #FF4500 0%, #FF5700 15%, #FF8C00 30%, #FFA500 45%, #FFA900 50%, #FFA500 55%, #FF8C00 70%, #FF5700 85%, #FF4500 100%)',
}));

const CourseAccordion = () => {
    const [expanded, setExpanded] = useState<number | false>(false);
    const [secondaryExpanded, setSecondaryExpanded] = useState<number | false>(false);
    const accordionRefs = useRef<(HTMLDivElement | null)[]>([]);

    const [CourseOverallData,setCourseOverallData] = useState<CourseData[]>([])

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSecondaryChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setSecondaryExpanded(isExpanded ? panel : false);
    };

    const handleScrollToAccordion = () => {
        if (expanded !== false) {
            const accordionElement = accordionRefs.current[expanded];
            if (accordionElement) {
                accordionElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    };

    const handleCloseSecondary = (index: number) => {
        setSecondaryExpanded(false);
        if (expanded !== false) {
            setTimeout(handleScrollToAccordion, 1000);
        }
    };

    useEffect(() => {
        if (expanded !== false) {
            setTimeout(handleScrollToAccordion, 1000);
        }
    }, [expanded]);


    useEffect(() => {
        const loadData = async () => {
            const { COURSES_OVERALL_DATA  } = await import('../Data/CoursePageData/CoursePageData');
            setCourseOverallData(COURSES_OVERALL_DATA );

        };
        loadData();
    }, []);

    return (
        <div>
            {CourseOverallData.map((course, index: number) => (
                <StyledPaper
                    key={index}
                    elevation={3}
                    sx={{
                        padding: expanded === index ? '0.5rem 0.25rem 1.0rem 0.25rem' : '1rem 1rem 1rem 1rem',
                        margin: expanded === index ? '0rem 0rem 0rem 0rem' : '1rem 0rem 1rem 0rem',
                    }}
                >
                    <Accordion
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        sx={{ width: '100%', m: 0, p: 0 }}
                        ref={el => (accordionRefs.current[index] = el)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    m: '1rem 1rem 1rem 2rem',
                                    justifyContent: 'center',
                                },
                                display: 'flex',
                                textAlign: 'center',
                                margin: 1,
                            }}
                        >
                            <div id={`CourseNameText-${index}`}>
                                <Typography sx={{ fontFamily: 'eBold' }} variant="h5">
                                    {course.CourseHeaderData.title}
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, overflow: 'hidden' }}>
                            <Suspense fallback={<div>Loading Header...</div>}>
                                <CourseHeader data={course.CourseHeaderData} />
                            </Suspense>
                            <Box sx={{ width: '100%', m: 0, p: 0 }}>
                                <Accordion
                                    expanded={secondaryExpanded === index}
                                    onChange={handleSecondaryChange(index)}
                                    sx={{ boxShadow: 'none' }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{
                                            '& .MuiAccordionSummary-content': {
                                                m: 0,
                                                justifyContent: 'center',
                                            },
                                        }}
                                    >
                                        <Typography sx={{ fontFamily: 'eReg' }} variant="h5">
                                            More Details
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: 2 }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <Suspense fallback={<div>Loading Image...</div>}>
                                                    <CourseImage imageUrl={course.CourseHeaderData.imageUrl} />
                                                </Suspense>
                                                <Suspense fallback={<div>Loading Instructor...</div>}>
                                                    <InstructorPage courseDetails1={course.InstructorsData} />
                                                </Suspense>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <Suspense fallback={<div>Loading Includes...</div>}>
                                                    <CourseIncludes data={course.CourseIncludeData} />
                                                </Suspense>
                                                <Suspense fallback={<div>Loading Learning Card...</div>}>
                                                    <LearningCard courseDetails2={course.LearningData} />
                                                </Suspense>
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', m: '2rem' }}>
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                startIcon={<CloseIcon />}
                                                onClick={() => handleCloseSecondary(index)}
                                                sx={{
                                                    borderRadius: 10,
                                                    fontFamily: 'eBold',
                                                }}
                                            >
                                                Close Details
                                            </Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </StyledPaper>
            ))}
        </div>
    );
};

const Courses = () => {
    return (
        <div>
            <Typography sx={{ fontFamily: 'eBold', marginTop: '2rem', marginBottom: '2rem' }} variant="h3" component="h1" gutterBottom>
                OUR COURSES
                <Divider textAlign={'left'} sx={{ fontFamily: 'Thin', fontSize: '0.8rem' }}>
                    COURSES SECTION
                </Divider>
            </Typography>

            <Box className={'FormHeadingMain'}>DIVE INTO DETAILS OF OUR COURSES</Box>

            <Typography
                variant="body1"
                sx={{ fontFamily: 'Thin', color: 'white', mt: 2 }}
            >
                Our courses are extensively designed from the ground up for you to learn and excel. Don’t worry about the extensivity, we got you covered.
            </Typography>

            <Element name="Courses_Accordion">
                <CourseAccordion />
            </Element>

            <Suspense fallback={<div>Loading Scroll Link...</div>}>
                <ScrollLink to="PlansAndPrices" pagePath="/Plans">
                    <Button variant="contained" color="warning" sx={{ m: '1rem 1rem 1rem 1rem', borderRadius: '5rem' }}>
                        <Typography variant="body1" sx={{ fontFamily: 'eBold', fontSize: '1.5rem', color: 'white', m: '1rem' }}>
                            OUR COURSE PRICES
                        </Typography>
                    </Button>
                </ScrollLink>
            </Suspense>

            <Divider textAlign={'right'} sx={{ fontFamily: 'Thin', fontSize: '0.8rem', mt: '4rem' }}>
                OUR COURSES
            </Divider>
        </div>
    );
};

export default Courses;
