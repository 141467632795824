interface WhyUsCardsInterface {
    title: string;
    description: string;
    imageSrc: string; // Assuming imageSrc is a string path
}


const WhyUsCards: WhyUsCardsInterface[] = [
    {
        title: 'Quran Courses Access 24/7',
        description: 'Learn Quran online anytime, anywhere with our round-the-clock accessible platform for flexible Islamic education.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/24_7.webp')
    },
    {
        title: 'Financial Aid & Compensation',
        description: 'We offer financial assistance and heavy discounts for the one who needs it. ( only elgible after indepth case study) ',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/needy.webp'),
    },
    {
        title: 'Easy Courses Enrollment',
        description: 'Start your Quranic journey instantly with our simple and hassle-free online registration process for Quran classes.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/OneClick.webp'),
    },
    {
        title: 'Course Completion Certification',
        description: 'Recognize your dedication with our exclusive certificates upon course completion and track your Quran learning progress with our monthly reports.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/Certification.webp'),
    },
    {
        title: 'Global Reach & Learning',
        description: 'Learn Quran from anywhere in the world with our accessible and user-friendly online platform for Islamic learning.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/Global.webp'),
    },
    {
        title: 'Personalized Courses Plans',
        description: 'Tailor your Quranic journey with our flexible learning options and personalized plans for effective Quran learning.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/CustomizePlan.webp'),
    },
    {
        title: 'Live Quran Classes',
        description: 'Benefit from live, one-on-one classes and engaging group interactions for an immersive Quran learning experience.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/liveQuranClass.webp'),
    },
    {
        title: 'Secure Payments',
        description: 'Enjoy peace of mind with our secure payment gateways and protected transactions for Quran classes online.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/SafePayments.webp'),
    },
    {
        title: 'Expert Quran Teachers',
        description: 'Learn from dedicated and qualified Quran teachers with years of experience in Islamic education and Quran teaching.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/1on1Classes.webp'),
    },
    {
        title: 'Diverse Methods',
        description: 'Choose from multiple expert teachers to find your perfect fit and learning style for effective Quran learning. Alternative Teachers are available if primary ones are on leave',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/MultipleTeachers.webp'),
    },
    {
        title: 'Monthly Reports',
        description: 'We offer Monthly reports of your progress so you can assess what action you need on your behalf to improve exponentially.',
        imageSrc: require('../Multimedia/Images/Components/WhyUsCards/MonthlyReport.webp'),
    },



];

export type {WhyUsCardsInterface}
export  {WhyUsCards};