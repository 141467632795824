import React, { useState, useMemo, useCallback } from 'react';
import "../../../Styles/Components/_WhyUsCard.scss"

interface MousePosition {
    x: number;
    y: number;
}

interface WhyUsCardProps {
    title: string;
    description: string;
    imageSrc: string;
}

const WhyUsCard: React.FC<WhyUsCardProps> = React.memo(({ title, description, imageSrc }) => {
    const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 });

    const handleMouseMove = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setMousePosition({ x, y });
    }, []);

    const gradientStyles = useMemo(() => ({
        '--x': `${mousePosition.x}px`,
        '--y': `${mousePosition.y}px`,
    } as React.CSSProperties), [mousePosition]);

    return (
        <div className="WhyUsCard" onMouseMove={handleMouseMove}>
            <div className="gradient-effect" style={gradientStyles} />
            <div className="WhyUsCard-content">
                <h1 className="WhyUsCard__title">{title}</h1>
                <img src={imageSrc} alt={title} className="WhyUsCard__image" loading="lazy" />
                <p className="WhyUsCard__paragraph">{description}</p>
            </div>
        </div>
    );
});

export default WhyUsCard;