import React from 'react';
import PolicySection from './PolicySection';
import VerifiedIcon from '@mui/icons-material/Verified';
import { PolicyDataSummary, PolicyDataDetailed } from '../../../Data/OurPolicyPageData/OurPolicyPageData';

const Certificates: React.FC = () => {
    return (
        <PolicySection
            title="Certificates"
            summary={PolicyDataSummary.Certificates}
            details={PolicyDataDetailed.Certificates}
            icon={<VerifiedIcon sx={{ color: '#ffd700' }} />}
        />
    );
};

export default Certificates;