import React from 'react';
import PolicySection from './PolicySection';
import LockIcon from '@mui/icons-material/Lock';
import { PolicyDataSummary, PolicyDataDetailed } from '../../../Data/OurPolicyPageData/OurPolicyPageData';

const PrivacyPolicy: React.FC = () => {
    return (
        <PolicySection
            title="Privacy Policy"
            summary={PolicyDataSummary.PrivacyPolicy}
            details={PolicyDataDetailed.PrivacyPolicy}
            icon={<LockIcon sx={{ color: '#4a148c' }} />}
        />
    );
};

export default PrivacyPolicy;