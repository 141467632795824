interface SocialMediaInterface {
        website     :string
        twitterX    : string
        linkedin    : string
        facebook    : string
        fbMessenger : string
        tiktok      : string
        gmail       : string
        instagram   : string
        phoneNumber : string

}

interface PhoneNumberCountryInterface {
        number: string
        image2: string
};

const SocialMediaLinks:SocialMediaInterface = {
        website     :"https://LuminaryQuran.com",
        twitterX    : 'https://x.com/LuminaryQuran?t=VJclJ9J1o8A559EhsyO1nA&s=09',
        linkedin    : 'https://www.linkedin.com/in/luminary-quran-99866a319?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
        facebook    : 'https://www.facebook.com/profile.php?id=61561514885527&mibextid=ZbWKwL',
        fbMessenger : 'http://m.me/337042886167789',
        tiktok      :  "https://www.tiktok.com/@luminary.quran?_t=8og0C9Ew2bQ&_r=1",
        gmail       :  "Info@luminaryquran.com",
        instagram   :   "https://www.instagram.com/luminary_quran?igsh=cjRqMWR5cGN3eDB6",
        phoneNumber :   "+1 (716) 657-6165"

}


const PhoneNoCountry : PhoneNumberCountryInterface[] = [
        {
                //        USA
                number: "+1 (716) 657-6165",
                image2: "USA/CAN"
        },
        {
                number:"Info@luminaryquran.com",
                image2 : "EMAIL"
        }

]



export {SocialMediaLinks,PhoneNoCountry}