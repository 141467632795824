import React from 'react';
import {Navbar0,ScrollLink} from "../ComponentsExports"
import "../../Styles/Components/_Footer.scss"
import { ReactComponent as PaypapSvg } from '../../Multimedia/Images/Components/Footer/paymentIcons/paypal.svg';
import { ReactComponent as VisaSvg } from '../../Multimedia/Images/Components/Footer/paymentIcons/visa.svg';
import { ReactComponent as MastercardSvg } from '../../Multimedia/Images/Components/Footer/paymentIcons/mastercard.svg';
import { ReactComponent as Maestro} from "../../Multimedia/Images/Components/Footer/paymentIcons/maestro.svg";
import paymentoption from '../../Multimedia/Images/Components/Footer/paymentIcons/securePayments.webp';
import securityoption from "../../Multimedia/Images/Components/Footer/PrivacyIcons/security.webp"
import WhiteLogo from "../../Multimedia/Logo/WhiteLogo.webp"
import secureDataBase from "../../Multimedia/Images/Components/Footer/PrivacyIcons/databaseSecurity.webp"
import secureConnections from "../../Multimedia/Images/Components/Footer/PrivacyIcons/SecureConnections.webp"
import VideoPlaftormSecurity from "../../Multimedia/Images/Components/Footer/PrivacyIcons/videoPlatform.webp"

const Footer = () => {

    const backgroundGradient = `transparent`;

    return (
        <footer className="footer">
            <div className="footer-logo">
            </div>

            <div className="footer-wrapper">
                <div className="footer-content">
                    <img className="main-logo" src={WhiteLogo} alt="Chakra Pro Logo" />
                    <div className="follow-icons">

                    </div>
                </div>

                <div className="section-content">
                    <div className="footer-section">
                        <h3>Our Company</h3>
                        <ul>
                            <ScrollLink to={"WhyUsFooter"} pagePath={"/"}>
                            <li><a href="">Why Luminary Quran</a></li>
                            </ScrollLink>
                            <ScrollLink to={"OurTeachersFooter"} pagePath={"/OurTeam"}>
                                <li><a href="">Our Teachers</a></li>
                            </ScrollLink>
                            <ScrollLink to={"OurClientsFooter"} pagePath={"/Testimonials"}>
                                <li><a href="">Our Clients</a></li>
                            </ScrollLink>
                            <ScrollLink to={"FAQsFooter"} pagePath={"/AboutUs"}>
                                <li><a href="">FAQs</a></li>
                            </ScrollLink>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Courses</h3>

                        <ul>
                            <ScrollLink to={"Courses_Accordion"} pagePath={"/Courses"}>
                                <li><a href="">Noorani Qaida</a></li>
                            </ScrollLink>
                            <ScrollLink to={"Courses_Accordion"} pagePath={"/Courses"}>
                                <li><a href="">Quran Reading & Memorization</a></li>
                            </ScrollLink>
                            <ScrollLink to={"Courses_Accordion"} pagePath={"/Courses"}>
                                <li><a href="">Tajweed, Tafsir and Qirat  </a></li>
                            </ScrollLink>
                            <ScrollLink to={"Courses_Accordion"} pagePath={"/Courses"}>
                                <li><a href="">Much More</a></li>
                            </ScrollLink>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Resources</h3>
                        <ul>
                            <ScrollLink to={"PlansAndPrices"} pagePath={"/Plans"}>
                                <li><a href="">Pricing</a></li>
                            </ScrollLink>
                            <ScrollLink to={"PlansAndPrices"} pagePath={"/Plans"}>
                                <li><a href="">Plans</a></li>
                            </ScrollLink>
                            <ScrollLink to={"OurClientsFooter"} pagePath={"/Testimonials"}>
                                <li><a href="">Testimonials</a></li>
                            </ScrollLink>

                            <ScrollLink to={"JoiningGuideSection"} pagePath={"/Plans"}>
                                <li><a href="">Joining Guide</a></li>
                            </ScrollLink>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Legal</h3>
                        <ul>
                            <ScrollLink to={"LegalSection"} pagePath={"/OurPolicy"}>
                                <li><a href="">Terms of Service</a></li>
                            </ScrollLink>

                            <ScrollLink to={"LegalSection"} pagePath={"/OurPolicy"}>
                                <li><a href="">Privacy & Refund Policy</a></li>
                            </ScrollLink>

                            <ScrollLink to={"LegalSection"} pagePath={"/OurPolicy"}>
                                <li><a href="">Quality Assurance</a></li>
                            </ScrollLink>

                            <ScrollLink to={"LegalSection"} pagePath={"/OurPolicy"}>
                                <li><a href="">Certificates</a></li>
                            </ScrollLink>
                        </ul>
                    </div>
                </div>
            </div>


            <div className={"footerPaymentSecurityContainer"}>
            <div className="footer-payment-info">
                <div className="payment-text">
                    {/*<paymentoption className="icon money-icon" />*/}
                    <img src={paymentoption} alt="Payment Options" className="icon money-icon" />
                    <span>We use secure payment</span>
                </div>
                <div className="payment-methods">
                    <MastercardSvg className="icon payment-icon" />
                    <Maestro className="icon payment-icon" />
                    <VisaSvg className="icon payment-icon" />
                    <PaypapSvg className="icon payment-icon" />
                </div>
            </div>

                <div className="footer-payment-info">
                    <div className="payment-text">
                        {/*<paymentoption className="icon money-icon" />*/}
                        <img src={securityoption} alt="Payment Options" className="icon money-icon" />
                        <span>We ensure your privacy</span>
                    </div>
                    <div className="payment-methods">
                        <img src={secureDataBase} className="icon secure-icon" />
                        <img src={secureConnections} className="icon secure-icon" />
                        <img src={VideoPlaftormSecurity} className="icon secure-icon" />

                    </div>
                </div>
            </div>
            <div className="footer-bottom">

                <div className="footer-copyright">
                    <img style={{height:"24px",width:"34px", marginRight:"10px"}} src={WhiteLogo}/> © 2024 Luminary Quran , Inc. All rights reserved.
                </div>
                <Navbar0 backgroundGradient={backgroundGradient} />


            </div>
        </footer>
    );
};

export default Footer;