import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Divider
} from '@mui/material';

interface CourseDetails1 {
    courseDescription: string;
    courseObjectives: string;
    targetAudience: string;
    courseStructure: string;
    coreSubjects: string;
    courseDetails: string;
    teachingStyle: string;
    correctionOfMistakes: string;
}


interface InstructorPageProps {
    courseDetails1: CourseDetails1;
}

const InstructorPage: React.FC<InstructorPageProps> = ({ courseDetails1 }) => {
    return (
        <Paper elevation={2} sx={{ padding: 3, margin: "0", borderRadius: "2rem" }}>
            <Typography  sx={{ fontFamily: 'eMed' ,marginTop:'3rem',marginBottom:'2rem'}} variant="h4" gutterBottom>
                FULL COURSE DETAILS
                <Divider sx={{marginTop:1.5}} />
            </Typography>
            {Object.entries(courseDetails1).map(([key, value]) => (
                <Box key={key} mb={3}>

                    <Typography sx={{ fontFamily: 'eBold' }} variant="h5" gutterBottom>
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    </Typography>
                    <Typography sx={{ fontFamily: 'Thin' }} variant="body1" paragraph>
                        {value}
                    </Typography>
                    <Divider />
                </Box>
            ))}
        </Paper>
    );
};

export default InstructorPage;