import React, { useState } from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Box,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface PolicySectionProps {
    title: string;
    summary: string;
    details: {
        Detailed: string[];
        HeadingOfEachParagraph: string[];
    };
    icon: React.ReactNode;
}

const PolicySection: React.FC<PolicySectionProps> = ({ title, summary, details, icon }) => {
    const [mainExpanded, setMainExpanded] = useState(false);
    const [detailsExpanded, setDetailsExpanded] = useState(false);

    return (
        <Accordion
            expanded={mainExpanded}
            onChange={() => setMainExpanded(!mainExpanded)}
            sx={{  }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography variant="h5" sx={{ display: 'flex', }}>
                    {icon}
                    <span style={{ fontFamily: 'eBold',marginLeft: '10px' }}>{title}</span>
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 4, pr: 4 }}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontFamily: 'eBold', mb: 1 }}>
                        Abstract
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            fontFamily: 'Thin',
                            textAlign: 'justify',
                            textIndent: '0.5in',
                            lineHeight: 2
                        }}
                    >
                        {summary}
                    </Typography>
                </Box>

                <Accordion
                    expanded={detailsExpanded}
                    onChange={() => setDetailsExpanded(!detailsExpanded)}
                    sx={{
                        backgroundColor: 'orange',
                        color: 'inherit',

                        transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
                        '&.Mui-expanded': {
                            backgroundColor: 'white',
                            color: 'black',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            color: 'white',
                            transition: 'color 0.3s ease-in-out',

                        },
                    }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ fontFamily: 'eMed' }}>Detailed</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                            variant="h4"
                            sx={{
                                fontFamily: 'eBold',
                                mb: 2,

                            }}
                        >
                            {title}
                        </Typography>
                        <List sx={{ pl: 0 }}>
                            {details.Detailed.map((paragraph, index) => (
                                <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'eMed',
                                                    mb: 1,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {details.HeadingOfEachParagraph[index]}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Thin',
                                                    textAlign: 'justify',
                                                    textIndent: '0.5in',
                                                    lineHeight: 2
                                                }}
                                            >
                                                {paragraph}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </AccordionDetails>
        </Accordion>
    );
};

export default PolicySection;