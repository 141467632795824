import React from 'react';
import PolicySection from './PolicySection';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { PolicyDataSummary, PolicyDataDetailed } from '../../../Data/OurPolicyPageData/OurPolicyPageData';

const ReturnsRefundPolicy: React.FC = () => {
    return (
        <PolicySection
            title="Returns & Refund Policy"
            summary={PolicyDataSummary.ReturnsRefundPolicy}
            details={PolicyDataDetailed.ReturnsRefundPolicy}
            icon={<AssignmentReturnIcon sx={{ color: '#ff9800' }} />}
        />
    );
};

export default ReturnsRefundPolicy;