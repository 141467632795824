// OurTeamPage.js
import React, { useState,useEffect,lazy, Suspense } from 'react';
import {Grid, Container, Fade, Button, Box, Typography, Divider} from '@mui/material';
import {Element} from "./ComponentsExports"
import { TeamMember,teamData } from '../Data/OurTeamPageData/ourTeamPageData';

import { scroller } from 'react-scroll';

const TeamMemberCard = lazy(() => import('./ComponentsExports').then(module => ({ default: module.TeamMemberCard })));
const TeamMemberDetail = lazy(() => import('./ComponentsExports').then(module => ({ default: module.TeamMemberDetail })));


const OurTeam = () => {
    const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);
    const [fadeOut, setFadeOut] = useState(false);

    const handleMoreInfo = (member: TeamMember) => {
        setSelectedMember(member);
        setFadeOut(false);
    };

    const handleBackToTeam = () => {
        setFadeOut(true);
    };

    useEffect(() => {
        if (fadeOut) {
            const timer = setTimeout(() => {
                setSelectedMember(null);
                setFadeOut(false);
                // window.scrollTo({ top: 0, behavior: 'smooth', offset:-70 });
                scroller.scrollTo('OurTeachersFooter', {
                    duration: 800,
                    delay: 0,
                    smooth: 'easeInOutQuart',
                    offset: 0,
                });
            }, 500  ); // This duration should match the Fade timeout duration

            return () => clearTimeout(timer);
        }
    }, [fadeOut]);

    return (
        <div>

            <Container>
                    <Typography sx={{ fontFamily: 'eBold', marginTop:'2rem', marginBottom:'2rem' }} variant="h3" component="h1" gutterBottom>
                        OUR TEAM
                        <Divider textAlign={"left"}  sx={{fontFamily:"Thin",fontSize:"0.8rem"}} > OUR TEAM</Divider>
                    </Typography>
                    <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                        Welcome to Luminary Quran, your trusted partner in learning and understanding the Holy Quran. We are dedicated to providing an enriching and comprehensive Quranic education to individuals of all ages and backgrounds.
                    </Typography>
                <Typography sx={{ fontFamily: 'eBold', marginTop:'3rem', marginBottom:'2rem' }} variant="h4" component="h1" gutterBottom>
                    MALE AND FEMALE BOTH TUTORS ARE AVAILABLE
                </Typography>
                <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                We have a diversity of both male and female teachers so our students are at ease and feel compatible with the respective teachers. </Typography>
            </Container>

            <Box className={"FormHeadingMain"}>
                    <Element name="OurTeachersFooter">
                        MEET OUR ESTEEMED TEAM
                    </Element>
                    <Typography
                        variant="body1"
                        sx={{ fontFamily: 'Thin', color: 'white', mt: 2,  }}
                    >
                        Dedicated to providing the highest quality of Islamic education and guidance.
                    </Typography>
                </Box>


            <Container>
                <Fade in={!selectedMember} timeout={500}>
                    <div style={{ display: !selectedMember ? 'block' : 'none' }}>
                        <Grid container spacing={6} >
                            {teamData.map((member, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Suspense fallback={<div>Loading Member Details...</div>}>
                                    <TeamMemberCard
                                        member={member}
                                        onMoreInfo={() => handleMoreInfo(member)}
                                    />
                                    </Suspense>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Fade>
                <Fade in={!!selectedMember && !fadeOut} timeout={500}>
                    <div style={{ display: selectedMember ? 'block' : 'none' }}>
                        {selectedMember && (
                            <>
                                <Suspense fallback={<div>Loading Member Details...</div>}>
                                <TeamMemberDetail member={selectedMember} />
                            </Suspense>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={handleBackToTeam}
                                    sx={{ m: "4rem",borderRadius:"2rem" }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ fontFamily: 'eBold',fontSize:"1.5rem", color: 'white', m:"1rem",  }}
                                    >
                                         Back
                                    </Typography>
                                </Button>
                            </>
                        )}
                    </div>
                </Fade>
            </Container>

            <Divider textAlign={"right"}  sx={{fontFamily:"Thin",fontSize:"0.8rem", mt:"4rem"}} > OUR TEAM</Divider>
        </div>
    );
};


export default OurTeam;
