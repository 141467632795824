// TeamMemberCard.tsx
import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { TeamMember } from '../../../Data/OurTeamPageData/ourTeamPageData';

interface TeamMemberCardProps {
    member: TeamMember;
    onMoreInfo?: () => void;
}



const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ member, onMoreInfo }) => {

    return (
        <Card
            sx={{
                maxWidth: 345,
                transition: 'all 0.3s ease-in-out, transform 0.5s ease-in-out',
                transform: 'perspective(1000px) rotateX(5deg) rotateY(30deg) scale(1)',
                '&:hover': {
                    transform: 'perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1.1)',
                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                },
            }}
        >
            <Box
                sx={{
                    height: 240,
                    clipPath: 'polygon(50% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 100%, 0% 25%)',
                    backgroundImage: `url(${member.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <CardContent>
                <Typography sx={{fontFamily:"eBold"}} gutterBottom variant="h5" component="div">
                    {member.name}
                </Typography>
                <Typography sx={{fontFamily:"eMed"}} variant="subtitle1" color="text.secondary" >
                    {member.title}
                </Typography>
                <Typography sx={{fontFamily:"Thin"}}  variant="body2" color="text.secondary">
                    {member.shortDescription}
                </Typography>
            </CardContent>
            <Button
                variant="contained"
                color="warning"
                onClick={onMoreInfo}
                fullWidth
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    py: 1.5,
                }}
            >
                <Typography sx={{fontFamily:"eBold"}} variant="body1" color="text.secondary">
                    MORE ABOUT ME
                </Typography>
            </Button>
        </Card>
    );
};

export default TeamMemberCard;